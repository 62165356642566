import { useState, useRef } from "react";
import { Uploader, Button } from "rsuite";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Photos from "../Photos/Photos";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPhotos, getResort } from "../../redux/actions/resort";

const ResortPhotos = ({ resort, getResort, addPhotos }) => {
  const uploaderRef = useRef(null);
  const [newPhotos, setNewPhotos] = useState([]);

  const appendPhoto = (file) => {
    const newPhotosData = [...newPhotos, file];
    setNewPhotos(newPhotosData);
  };

  const addPhotoHandler = async () => {
    const photoItems = document.querySelectorAll(
      ".rs-uploader-file-item-picture"
    );

    await addPhotos({
      photos: newPhotos,
      resort_id: resort.resort_id,
    });
    await getResort(resort.resort_id);

    setNewPhotos([]);

    uploaderRef.current.defaultFileList = [];
    uploaderRef.current.fileList = [];

    for (const items of photoItems) {
      items.remove();
    }
  };

  return (
    <div className="card mt-5">
      <div className="card-header">
        <p className="card-title">Resort Photos</p>
      </div>
      <Photos photos={resort?.photos} resort={resort} />
      <div className="card-body">
        <div>
          <Uploader
            ref={uploaderRef}
            multiple
            listType="picture"
            action={`${process.env.REACT_APP_BACKEND_BASE_URL}/file/upload`}
            onSuccess={(res) => {
              appendPhoto(res.data);
            }}
          >
            <button>
              <CameraRetroIcon />
            </button>
          </Uploader>
          {newPhotos.length !== 0 && (
            <Button
              appearance="primary"
              className="w-16 h-16 -mt-14 ml-5"
              onClick={() => addPhotoHandler()}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

ResortPhotos.propTypes = {
  addPhotos: PropTypes.func.isRequired,
  getResort: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getResort, addPhotos })(ResortPhotos);
