import { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import DataTable from "react-data-table-component";
import { DestinationColumns } from "../../constants/columns";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllDestinations } from "../../redux/actions/destination";

// Components
import AddDestination from "../../components/Destination/AddDestination/AddDestination";
import UpdateDestination from "../../components/Destination/UpdateDestination/UpdateDestination";
import DeleteDestination from "../../components/Destination/DeleteDestination/DeleteDestination";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const Destinations = ({ getAllDestinations, destinations, loading }) => {
  const [data, setData] = useState([]);
  const [isCreateDestination, setIsCreateDestination] = useState(false);
  const [isUpdateDestination, setIsUpdateDestination] = useState(false);
  const [isDeleteDestination, setIsDeleteDestination] = useState(false);
  const [destinationData, setDestinationData] = useState({});

  useEffect(() => {
    getAllDestinations();
  }, []);

  useEffect(() => {
    setData(destinations);
  }, [destinations, setData]);

  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <p className="card-title">Destination List</p>
          <button
            type="button"
            className="text-blue-500"
            onClick={() => setIsCreateDestination(true)}
          >
            <PlusCircleIcon className="w-6" />
          </button>
        </div>
      </div>
      <DataTable
        columns={DestinationColumns({
          setDestinationData,
          setIsUpdateDestination,
          setIsDeleteDestination,
        })}
        data={data}
        progressPending={loading}
        progressComponent={<LoadingSpinner />}
        pagination
        paginationServer
        highlightOnHover
        pointerOnHover
      />
      <AddDestination
        showModal={isCreateDestination}
        setShowModal={setIsCreateDestination}
      />
      <UpdateDestination
        showModal={isUpdateDestination}
        setShowModal={setIsUpdateDestination}
        destination={destinationData}
      />
      <DeleteDestination
        showModal={isDeleteDestination}
        setShowModal={setIsDeleteDestination}
        destination={destinationData}
      />
    </div>
  );
};

Destinations.propTypes = {
  getAllDestinations: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  destinations: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  destinations: state.destinationReducer.destinations,
  loading: state.destinationReducer.loading,
});

export default connect(mapStateToProps, { getAllDestinations })(Destinations);
