import { useEffect, useState } from "react";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "../../components/StayCard/StayCard";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllDestinations } from "../../redux/actions/destination";
import { getAllPackages } from "../../redux/actions/package";
import { PackageLoading } from "../../utils/loader";

// OTHER DEMO WILL PASS PROPS

const SectionGridFeaturePlaces = ({
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that we recommends for you",
  headingIsCenter,
  destinations,
  packages,
  getAllDestinations,
  getAllPackages,
  loading,
}) => {
  const [tabs, setTabs] = useState([{ destination_id: 0, name: "All" }]);
  const [listings, setListings] = useState([]);

  const renderCard = (packageData) => {
    return <StayCard key={packageData.package_id} data={packageData} />;
  };

  useEffect(() => {
    getAllDestinations();
    getAllPackages();
  }, []);

  useEffect(() => {
    setListings(packages);
  }, [packages]);

  useEffect(() => {
    setTabs((prev) => {
      const uniqueTabs = [
        ...new Map(
          [...prev, ...destinations].map((item) => [item.destination_id, item])
        ).values(),
      ];
      return uniqueTabs;
    });
  }, [destinations]);

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={tabs[0]}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={(item) => {
          if (item.name !== "All") {
            getAllPackages({ destination: item.name });
          } else {
            getAllPackages();
          }
        }}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {listings.map((packageData) => renderCard(packageData))}
        {!loading && !listings.length && (
          <p className="text-gray-500 px-3">no package available</p>
        )}
      </div>
      {loading && <PackageLoading />}
      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div> */}
    </div>
  );
};

SectionGridFeaturePlaces.propTypes = {
  getAllDestinations: PropTypes.func.isRequired,
  getAllPackages: PropTypes.func.isRequired,
  destinations: PropTypes.array.isRequired,
  packages: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  destinations: state.destinationReducer.destinations,
  packages: state.packageReducer.packages,
  loading: state.packageReducer.loading,
});

export default connect(mapStateToProps, { getAllPackages, getAllDestinations })(
  SectionGridFeaturePlaces
);
