import { CalendarIcon } from "@heroicons/react/24/outline";

// Redux
import { connect } from "react-redux";

const StayDatesRangeInput = ({ startDate, endDate }) => {
  return (
    <div className="p-4 flex gap-3">
      <div className="text-neutral-300 dark:text-neutral-400">
        <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
      </div>
      <div className="flex-grow text-left">
        <span className="block xl:text-lg font-semibold">
          {startDate?.toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
          }) || "no date selected"}
          {endDate
            ? " - " +
              endDate?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
              })
            : ""}
        </span>
        <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
          {"Check in - Check out"}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.reservationReducer.startDate,
  endDate: state.reservationReducer.endDate,
});

export default connect(mapStateToProps)(StayDatesRangeInput);
