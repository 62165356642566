import {
  GET_ROOM_ITEMS,
  GET_ROOM_ITEM,
  ADD_ROOM_ITEM,
  UPDATE_ROOM_ITEM,
  DELETE_ROOM_ITEM,
  ROOM_ITEM_ERROR,
} from "../actions/types";

const initialState = {
  room_items: null,
  room_item: null,
  loading: true,
  error: null,
};

const roomItemReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ROOM_ITEMS:
      return {
        ...state,
        room_items: payload,
        loading: false,
      };
    case GET_ROOM_ITEM:
    case ADD_ROOM_ITEM:
    case UPDATE_ROOM_ITEM:
    case DELETE_ROOM_ITEM:
      return {
        ...state,
        room_item: payload,
        loading: false,
      };
    case ROOM_ITEM_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default roomItemReducer;
