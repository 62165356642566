import DatePicker from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "../../components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "../../components/DatePickerCustomDay";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setStartDate } from "../../redux/actions/search";
import { setEndDate } from "../../redux/actions/search";

const StayDatesRangeInput = ({
  className = "",
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}) => {
  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

StayDatesRangeInput.propTypes = {
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  startDate: state.searchReducer.startDate,
  endDate: state.searchReducer.endDate,
});

export default connect(mapStateToProps, { setStartDate, setEndDate })(
  StayDatesRangeInput
);
