import {
  SET_SEARCH_LOCATION,
  SET_SEARCH_GUESTS,
  SET_SEARCH_CHECK_IN,
  SET_SEARCH_CHECK_OUT,
  RESET_SEARCH,
} from "../actions/types";

const initialState = {
  locationInputTo: "",
  guestInput: {
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  },
  startDate: null,
  endDate: null,
};

const searchReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SEARCH_LOCATION:
      return {
        ...state,
        locationInputTo: payload,
      };
    case SET_SEARCH_GUESTS:
      return {
        ...state,
        guestInput: payload,
      };
    case SET_SEARCH_CHECK_IN:
      return {
        ...state,
        startDate: payload,
      };
    case SET_SEARCH_CHECK_OUT:
      return {
        ...state,
        endDate: payload,
      };
    case RESET_SEARCH:
      return (state = initialState);
    default:
      return state;
  }
};

export default searchReducer;
