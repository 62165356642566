import {
  GET_ALL_SCHEDULES,
  ADD_SCHEDULE,
  SCHEDULE_ERROR,
} from "../actions/types";

const initialState = {
  schedules: [],
  schedule: null,
  loading: true,
  error: null,
};

const scheduleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SCHEDULES:
      return {
        ...state,
        schedules: payload,
        loading: false,
      };
    case ADD_SCHEDULE:
      return {
        ...state,
        schedule: payload,
        loading: false,
      };
    case SCHEDULE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default scheduleReducer;
