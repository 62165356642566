import {
  GET_ROOMS,
  GET_ROOM,
  ADD_ROOM,
  ADD_ROOM_PHOTOS,
  UPDATE_ROOM,
  DELETE_ROOM,
  ROOM_ERROR,
} from "../actions/types";

const intialState = {
  rooms: null,
  room: null,
  photos: null,
  loading: true,
  error: null,
};

const roomReducer = (state = intialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ROOMS:
      return {
        ...state,
        rooms: payload,
        loading: false,
      };
    case GET_ROOM:
    case UPDATE_ROOM:
    case DELETE_ROOM:
    case ADD_ROOM:
      return {
        ...state,
        room: payload,
        loading: false,
      };
    case ADD_ROOM_PHOTOS:
      return {
        ...state,
        photos: payload,
        loading: false,
      };
    case ROOM_ERROR:
      return {
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default roomReducer;
