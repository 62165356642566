import GallerySlider from "../../components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "../../data/listings";
import StartRating from "../../components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "../../components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "../../components/SaleOffBadge/SaleOffBadge";
import Badge from "../../shared/Badge/Badge";

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCardH = ({ className = "", data }) => {
  const {
    package_id,
    name,
    short_description,
    price,
    discount,
    new_price,
    room,
    package_images,
    slug,
  } = data;

  const { resort } = room;

  const href = `/package/view/${slug}`;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={package_images.slice(0, 5)}
          uniqueID={`StayCardH_${package_id}`}
          href={href}
        />
        {discount && (
          <SaleOffBadge
            className="absolute left-3 top-3"
            desc={`-${discount}%`}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {resort.name} in {resort.address}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div>
          <span className="text-base font-semibold">
            <span className="line-through text-xs text-gray-400 block">
              ${price}
            </span>
            ${new_price} <span>USD</span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link to={href} className="absolute inset-0"></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
