import {
  GET_ROOM_ITEMS,
  GET_ROOM_ITEM,
  ADD_ROOM_ITEM,
  UPDATE_ROOM_ITEM,
  DELETE_ROOM_ITEM,
  ROOM_ITEM_ERROR,
} from "./types";
import api from "../../utils/api";
import { setAlert, removeAllAlerts } from "./alert";
import { setError, removeError } from "./error";

// Get all room items
export const getAllRoomItems = () => async (dispatch) => {
  try {
    const res = await api.get("/room-item");
    const response = res.data;

    dispatch({
      type: GET_ROOM_ITEMS,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: ROOM_ITEM_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Get all items by room
export const getAllRoomItemsByRoom = (roomId) => async (dispatch) => {
  try {
    const res = await api.get(`/room/${roomId}/items`);
    const response = res.data;

    dispatch({
      type: GET_ROOM_ITEMS,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: ROOM_ITEM_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Get room item
export const getRoomItem = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/room-item/${id}`);
    const response = res.data;

    dispatch({
      type: GET_ROOM_ITEM,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: ROOM_ITEM_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Add room item
export const addRoomItem = (formData) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());

  try {
    const res = await api.post("/room-item", formData);
    const response = res.data;

    dispatch({
      type: ADD_ROOM_ITEM,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: ROOM_ITEM_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Update room item
export const updateRoomItem = (id, formData) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());

  try {
    const res = await api.put(`/room-item/${id}`, formData);
    const response = res.data;

    dispatch({
      type: UPDATE_ROOM_ITEM,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: ROOM_ITEM_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Delete room item
export const deleteRoomItem = (id) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());

  try {
    const res = await api.delete(`/room-item/${id}`);
    const response = res.data;

    dispatch({
      type: DELETE_ROOM_ITEM,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: ROOM_ITEM_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};
