import { Fragment, useState, useEffect } from "react";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "../../../shared/Button/ButtonSecondary";
import ButtonClose from "../../../shared/ButtonClose/ButtonClose";
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import Loader from "../../../components/Loader";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPackageBySlug } from "../../../redux/actions/package";
import { setImages } from "../../../redux/actions/image";
import { setReservationPackage } from "../../../redux/actions/reservation";

const StayDetailPageContainer = ({
  packageData,
  packageData: {
    package_id,
    name,
    short_description,
    description,
    days,
    price,
    discount,
    new_price,
    adults,
    children,
    infants,
    room,
    package_images,
  },
  reservation,
}) => {
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const resort = room.resort;

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {name}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">{resort.address}</span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div>{short_description}</div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Package</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {description}
        </div>
        <h2 className="text-2xl font-semibold">{resort.name}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {resort.description}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">
            Resort Amenities ({resort.name})
          </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {resort.amenities
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className={`text-3xl las la-check-circle`}></i>
                <span className=" ">{item.name}</span>
                {item.is_charged && (
                  <span className="px-2 bg-red-500 text-white rounded">$</span>
                )}
              </div>
            ))}
        </div>
        {resort.amenities.length > 11 && (
          <div>
            <ButtonSecondary onClick={openModalAmenities}>
              View more 20 amenities
            </ButtonSecondary>
          </div>
        )}
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderRoom = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Room ({room.name})</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {room.description}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {room.room_items.map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las la-check-circle`}></i>
              <span className=" ">{item.name}</span>
              {item.is_charged && (
                <span className="px-2 bg-red-500 text-white rounded">$</span>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {resort.amenities.map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las la-check-circle`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {resort.address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB2BW8rx615MZ2VLVRXrD1suJ7sU0Ibp84&q=${resort?.latlong}&zoom=15`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    const handlerReserve = async () => {
      router("/checkout");
    };

    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <div>
            <span className="block text-xs text-gray-400 line-through">
              {price}
            </span>
            <span className="text-3xl font-semibold">${new_price}</span>
          </div>
          <span className="text-yellow-500 font-bold">-{discount}%</span>
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput className="flex-1 z-[11]" />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            className="flex-1"
            adults={adults}
            children={children}
            infants={infants}
          />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {days > 1 ? (
                <>
                  {days} Days and {days - 1} Nights
                </>
              ) : (
                <>{days} Day</>
              )}
            </span>
            <span>${new_price}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>${new_price}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary
          disabled={reservation.startDate && reservation.endDate ? false : true}
          onClick={() => handlerReserve()}
        >
          Reserve
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={package_images[0]}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {package_images
            .filter((_, i) => i >= 1 && i < 5)
            .map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                  <img
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={item || ""}
                    alt=""
                    sizes="400px"
                  />
                </div>

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={handleOpenModalImageGallery}
                />
              </div>
            ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderRoom()}
          <SectionDateRange days={days} package_id={packageData?.package_id} />
          {renderSection7()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

const ListingStayDetailPage = ({
  getPackageBySlug,
  packageData,
  loading,
  setImages,
  reservation,
  setReservationPackage,
}) => {
  const { slug } = useParams();

  useEffect(() => {
    getPackageBySlug(slug);
  }, {});

  useEffect(() => {
    setImages(packageData?.package_images);
    setReservationPackage(packageData);
  }, [packageData]);

  return (
    <DetailPagetLayout>
      {loading ? (
        <Loader />
      ) : (
        <StayDetailPageContainer
          packageData={packageData}
          reservation={reservation}
        />
      )}
    </DetailPagetLayout>
  );
};

ListingStayDetailPage.propTypes = {
  getPackageBySlug: PropTypes.func.isRequired,
  setImages: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  reservation: PropTypes.object.isRequired,
  setReservationPackage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  packageData: state.packageReducer.package,
  loading: state.packageReducer.loading,
  reservation: state.reservationReducer,
});

export default connect(mapStateToProps, {
  getPackageBySlug,
  setImages,
  setReservationPackage,
})(ListingStayDetailPage);
