import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/solid";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteResort } from "../../../redux/actions/resort";

// Components
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";

const DeleteResort = ({ showModal, setShowModal, resort, deleteResort }) => {
  const cancelButtonRef = useRef(null);

  const deleteHandler = async () => {
    await deleteResort(resort.resort_id);
    setShowModal(false);
  };

  return (
    <div className="flex h-full items-center justify-center sm:p-0">
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setShowModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="sm:flex sm:items-start border-b border-b-slate-100">
                    <div className="flex items-center mx-5 my-3">
                      <TrashIcon
                        className="h-6 w-6 text-gray-900"
                        aria-hidden="true"
                      />
                      <Dialog.Title
                        as="h3"
                        className="font-semibold leading-6 text-gray-900 ml-3"
                      >
                        Delete Destination
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="px-5 py-5">
                    <p className="mb-5">
                      Are you sure want to to delete{" "}
                      <span className="font-bold">{resort.name}</span>?
                    </p>
                    <div className="flex justify-start gap-2">
                      <ButtonPrimary
                        type="button"
                        onClick={() => deleteHandler()}
                      >
                        Yes
                      </ButtonPrimary>
                      <ButtonPrimary
                        type="button"
                        className="bg-red-500 hover:bg-red-400"
                        onClick={() => setShowModal(false)}
                        ref={cancelButtonRef}
                      >
                        No
                      </ButtonPrimary>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

DeleteResort.propTypes = {
  deleteResort: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { deleteResort })(DeleteResort);
