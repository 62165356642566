import SectionHero from "../../components/SectionHero/SectionHero";
import SectionSubscribe2 from "../../components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "../../components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "../../components/SectionClientSay/SectionClientSay";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />
        <SectionOurFeatures />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>
        <SectionHowItWork />
        {/* <SectionSubscribe2 /> */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>
        <SectionVideos />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
