import PropTypes from "prop-types";
import { connect } from "react-redux";
import { svgs } from "../../constants/constants";

const Alert = ({ alerts }) => {
  const InfoAlert = (alert) => {
    return (
      <div className="bg-blue-100 p-5 w-full border-l-4 border-blue-500 rounded-md">
        <div className="flex space-x-3">
          {svgs.infoCircleSolid}
          <div className="flex-1 leading-tight text-sm text-blue-700">
            {alert.alert.msg}
          </div>
        </div>
      </div>
    );
  };

  const SuccessAlert = (alert) => {
    return (
      <div className="bg-green-100 p-5 w-full rounded-md">
        <div className="flex justify-between">
          <div className="flex space-x-3">
            <div className="flex-1 leading-tight text-sm text-green-700 font-medium">
              {alert.alert.msg}
            </div>
          </div>
          {svgs.checkCircleSolid}
        </div>
      </div>
    );
  };

  const DangerAlert = (alert) => {
    return (
      <div className="bg-red-100 p-5 w-full rounded-md">
        <div className="flex space-x-3">
          {svgs.removeCircleSolid}
          <div className="leading-tight flex flex-col space-y-2">
            <div className="text-sm font-medium text-red-700">
              {alert.alert.msg}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {alerts.map((alert) => (
        <div key={alert.id} style={{ marginBottom: 20 }}>
          {alert.type === "info" && <InfoAlert alert={alert} />}
          {alert.type === "success" && <SuccessAlert alert={alert} />}
          {alert.type === "danger" && <DangerAlert alert={alert} />}
        </div>
      ))}
    </>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alertReducer,
});

export default connect(mapStateToProps)(Alert);
