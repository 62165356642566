import { useEffect, useState } from "react";
import api from "../../utils/api";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Redux
import { connect } from "react-redux";

// Components
import CheckOutForm from "../../components/CheckOutForm/CheckOutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckOutPagePageMain = ({
  className = "",
  packageData,
  startDate,
  endDate,
}) => {
  const [reservation] = useState({
    package: packageData,
    startDate: startDate,
    endDate: endDate,
  });

  const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    if (reservation.package) {
      api
        .post("/reservation/create-payment", {
          total_amount: reservation?.package?.new_price,
        })
        .then((res) => {
          setClientSecret(res.data.clientSecret);
        });
    }
  }, [reservation]);

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={reservation.package.featured_img_url}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {reservation.package.short_description}
              </span>
              <span className="text-base font-medium mt-1 block">
                {reservation.package.name}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {reservation.package.days > 1 ? (
                <>
                  {reservation.package.days} Days and{" "}
                  {reservation.package.days - 1} Nights
                </>
              ) : (
                <>{reservation.package.days} Day</>
              )}
            </span>
            <span>${reservation.package.new_price}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>${reservation.package.new_price}</span>
          </div>
        </div>
      </div>
    );
  };

  return reservation.package && reservation.startDate && reservation.endDate ? (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
          {clientSecret ? (
            <Elements options={options} stripe={stripePromise}>
              <CheckOutForm
                packageData={packageData}
                reservation={reservation}
                startDate={startDate}
                endDate={endDate}
              />
            </Elements>
          ) : (
            <div className="flex items-center justify-center">
              <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-indigo-500 border-t-transparent"></div>
            </div>
          )}
        </div>

        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  ) : (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-60 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <p>
          There was an error. Go back to{" "}
          <a href="/" className="text-yellow-500">
            home
          </a>
          .
        </p>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  packageData: state.reservationReducer.package,
  startDate: state.reservationReducer.startDate,
  endDate: state.reservationReducer.endDate,
});

export default connect(mapStateToProps)(CheckOutPagePageMain);
