import { useRef, useEffect, useState } from "react";
import { Uploader, Message, Loader, useToaster } from "rsuite";
import ImageIcon from "@rsuite/icons/legacy/Image";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPackage } from "../../../redux/actions/package";

// Formik & Yup
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Components
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import Alert from "../../../components/Alert/Alert";

const validationSchema = Yup.object().shape({
  room_id: Yup.number().required("Room ID is required"),
  name: Yup.string().required("Name is required"),
  short_description: Yup.string().required("Short Description is required"),
  description: Yup.string().required("Description is required"),
  days: Yup.number()
    .min(1, "Minimum of 1 day")
    .required("Duration is required"),
  price: Yup.number().required("Price is required"),
  discount: Yup.number().required("Discount is required"),
  featured_img_url: Yup.string().required("Featured image is required"),
  adults: Yup.number()
    .min(1, "Minimum of 1")
    .required("Number of adults is required"),
  children: Yup.number().required("Number of children is required"),
  infants: Yup.number().required("Number of infants is required"),
});

const AddPackageForm = ({
  cancelButtonRef,
  setShowModal,
  errors,
  room,
  setSelectedRoom,
  addPackage,
}) => {
  const formikRef = useRef(null);
  const uploaderRef = useRef(null);
  const toaster = useToaster();

  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);

  const initialValues = {
    room_id: room?.room_id,
    name: "",
    short_description: "",
    description: "",
    days: "",
    price: "",
    discount: "",
    featured_img_url: "",
    adults: 0,
    children: 0,
    infants: 0,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    await addPackage(values);
    setSubmitting(false);
  };

  const previewFile = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    formikRef.current.setErrors(errors);
  }, [errors, formikRef]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        values,
      }) => (
        <Form className="grid grid-cols-1 gap-6 px-5 py-5">
          <Alert />
          <div className="border border-blue-300 px-4 py-3 rounded">
            <p className="tex-gray-500 flex justify-between">
              {room.name}{" "}
              <button
                type="button"
                className="text-blue-500 font-bold"
                onClick={() => setSelectedRoom(null)}
              >
                Change
              </button>
            </p>
          </div>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.featured_img_url &&
                touched.featured_img_url &&
                "text-red-500 dark:text-red-500"
              }`}
            >
              Featured Image
            </span>
            <Uploader
              ref={uploaderRef}
              className="w-full"
              fileListVisible={false}
              listType="picture"
              action={`${process.env.REACT_APP_BACKEND_BASE_URL}/file/upload`}
              onUpload={(file) => {
                setUploading(true);
                previewFile(file.blobFile, (value) => {
                  setFileInfo(value);
                });
              }}
              onSuccess={(response, file) => {
                setUploading(false);
                toaster.push(
                  <Message type="success">Uploaded successfully</Message>
                );
                console.log(response);
                setFieldValue("featured_img_url", response.data.file);
              }}
              onError={() => {
                setFileInfo(null);
                setUploading(false);
                toaster.push(<Message type="error">Upload failed</Message>);
              }}
            >
              <button
                type="button"
                style={{ height: 150, width: 400, boxSizing: "border-box" }}
              >
                {uploading && <Loader backdrop center />}
                {fileInfo ? (
                  <img src={fileInfo} width="100%" height="100%" alt="" />
                ) : (
                  <ImageIcon style={{ fontSize: 80 }} />
                )}
              </button>
            </Uploader>
            {errors.featured_img_url && touched.featured_img_url && (
              <div className="text-red-500 text-sm font-bold">
                {errors.featured_img_url}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.name && touched.name && "text-red-500 dark:text-red-500"
              }`}
            >
              Package Name
            </span>
            <Field
              type="text"
              name="name"
              className={`mt-1 ${
                errors.name &&
                touched.name &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
                setFieldTouched("name", true, false);
              }}
            />
            {errors.name && touched.name && (
              <div className="text-red-500 text-sm font-bold">
                {errors.name}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.short_description &&
                touched.short_description &&
                "text-red-500 dark:text-red-500"
              }`}
            >
              Short Description
            </span>
            <Field
              type="text"
              name="short_description"
              className={`mt-1 ${
                errors.short_description &&
                touched.short_description &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("short_description", e.target.value);
                setFieldTouched("short_description", true, false);
              }}
            />
            {errors.short_description && touched.short_description && (
              <div className="text-red-500 text-sm font-bold">
                {errors.short_description}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.description &&
                touched.description &&
                "text-red-500 dark:text-red-500"
              }`}
            >
              Description
            </span>
            <textarea
              name="description"
              rows="6"
              className={`mt-1 ${
                errors.description &&
                touched.description &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
                setFieldTouched("description", true, false);
              }}
            />
            {errors.description && touched.description && (
              <div className="text-red-500 text-sm font-bold">
                {errors.description}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.days && touched.days && "text-red-500 dark:text-red-500"
              }`}
            >
              Duration (Days)
            </span>
            <Field
              type="number"
              name="days"
              className={`mt-1 ${
                errors.days &&
                touched.days &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("days", e.target.value);
                setFieldTouched("days", true, false);
              }}
            />
            {errors.days && touched.days && (
              <div className="text-red-500 text-sm font-bold">
                {errors.days}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.name && touched.name && "text-red-500 dark:text-red-500"
              }`}
            >
              Price
            </span>
            <Field
              type="number"
              name="price"
              className={`mt-1 ${
                errors.price &&
                touched.price &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("price", e.target.value);
                setFieldTouched("price", true, false);
              }}
            />
            {errors.price && touched.price && (
              <div className="text-red-500 text-sm font-bold">
                {errors.price}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.name && touched.name && "text-red-500 dark:text-red-500"
              }`}
            >
              Discount
            </span>
            <Field
              type="number"
              name="discount"
              className={`mt-1 ${
                errors.discount &&
                touched.discount &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("discount", e.target.value);
                setFieldTouched("discount", true, false);
              }}
            />
            {errors.discount && touched.discount && (
              <div className="text-red-500 text-sm font-bold">
                {errors.discount}
              </div>
            )}
          </label>
          <div className="flex justify-evenly gap-8">
            <label className="block">
              <span
                className={`text-neutral-800 dark:text-neutral-200 ${
                  errors.adults &&
                  touched.adults &&
                  "text-red-500 dark:text-red-500"
                }`}
              >
                Adults
              </span>
              <div className="relative flex items-center">
                <button
                  type="button"
                  id="decrement-button"
                  data-input-counter-decrement="counter-input"
                  className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  onClick={() => {
                    const adults = parseInt(values.adults);
                    let newValue = 0;
                    if (adults > 0) {
                      newValue = adults - 1;
                    }
                    setFieldValue("adults", newValue);
                  }}
                >
                  <svg
                    className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 2"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h16"
                    />
                  </svg>
                </button>
                <input
                  type="text"
                  id="counter-input"
                  data-input-counter
                  className="flex-shrink-0 text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center"
                  placeholder=""
                  value={values.adults}
                  required
                />
                <button
                  type="button"
                  id="increment-button"
                  data-input-counter-increment="counter-input"
                  className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  onClick={() => {
                    const adults = parseInt(values.adults);
                    let newValue = adults + 1;
                    setFieldValue("adults", newValue);
                  }}
                >
                  <svg
                    className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 1v16M1 9h16"
                    />
                  </svg>
                </button>
              </div>
              {errors.adults && touched.adults && (
                <div className="text-red-500 text-sm font-bold">
                  {errors.adults}
                </div>
              )}
            </label>
            <label className="block">
              <span
                className={`text-neutral-800 dark:text-neutral-200 ${
                  errors.children &&
                  touched.children &&
                  "text-red-500 dark:text-red-500"
                }`}
              >
                Children
              </span>
              <div className="relative flex items-center">
                <button
                  type="button"
                  id="decrement-button"
                  data-input-counter-decrement="counter-input"
                  className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  onClick={() => {
                    const children = parseInt(values.children);
                    let newValue = 0;
                    if (children > 0) {
                      newValue = children - 1;
                    }
                    setFieldValue("children", newValue);
                  }}
                >
                  <svg
                    className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 2"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h16"
                    />
                  </svg>
                </button>
                <input
                  type="text"
                  id="counter-input"
                  data-input-counter
                  className="flex-shrink-0 text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center"
                  placeholder=""
                  value={values.children}
                  required
                />
                <button
                  type="button"
                  id="increment-button"
                  data-input-counter-increment="counter-input"
                  className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  onClick={() => {
                    const children = parseInt(values.children);
                    let newValue = children + 1;
                    setFieldValue("children", newValue);
                  }}
                >
                  <svg
                    className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 1v16M1 9h16"
                    />
                  </svg>
                </button>
              </div>
              {errors.children && touched.children && (
                <div className="text-red-500 text-sm font-bold">
                  {errors.children}
                </div>
              )}
            </label>
            <label className="block">
              <span
                className={`text-neutral-800 dark:text-neutral-200 ${
                  errors.infants &&
                  touched.infants &&
                  "text-red-500 dark:text-red-500"
                }`}
              >
                Infants
              </span>
              <div className="relative flex items-center">
                <button
                  type="button"
                  id="decrement-button"
                  data-input-counter-decrement="counter-input"
                  className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  onClick={() => {
                    const infants = parseInt(values.infants);
                    let newValue = 0;
                    if (infants > 0) {
                      newValue = infants - 1;
                    }
                    setFieldValue("infants", newValue);
                  }}
                >
                  <svg
                    className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 2"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h16"
                    />
                  </svg>
                </button>
                <input
                  type="text"
                  id="counter-input"
                  data-input-counter
                  className="flex-shrink-0 text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-[2.5rem] text-center"
                  placeholder=""
                  value={values.infants}
                  required
                />
                <button
                  type="button"
                  id="increment-button"
                  data-input-counter-increment="counter-input"
                  className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  onClick={() => {
                    const infants = parseInt(values.infants);
                    let newValue = infants + 1;
                    setFieldValue("infants", newValue);
                  }}
                >
                  <svg
                    className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 1v16M1 9h16"
                    />
                  </svg>
                </button>
              </div>
              {errors.infants && touched.infants && (
                <div className="text-red-500 text-sm font-bold">
                  {errors.infants}
                </div>
              )}
            </label>
          </div>
          <div className="flex justify-end gap-2">
            <ButtonPrimary type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Creating" : "Create"}
            </ButtonPrimary>
            <ButtonPrimary
              type="button"
              className="bg-red-500 hover:bg-red-400"
              onClick={() => setShowModal(false)}
              ref={cancelButtonRef}
            >
              Cancel
            </ButtonPrimary>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddPackageForm.propTypes = {
  errors: PropTypes.array.isRequired,
  addPackage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errorReducer,
});

export default connect(mapStateToProps, { addPackage })(AddPackageForm);
