import { Link } from "react-router-dom";
import {
  PencilSquareIcon,
  TrashIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { Toggle } from "rsuite";

export const DestinationColumns = (props) => [
  {
    name: "Name",
    selector: (row) => row?.name,
    sortable: true,
    gap: 1,
  },
  {
    action: "Action",
    gap: 1,
    cell: (row) => (
      <div
        key={row.destination_id}
        className="flex flex-row justify-center items-center"
      >
        <button
          type="button"
          className="text-green-700"
          onClick={() => {
            props.setDestinationData(row);
            props.setIsUpdateDestination(true);
          }}
        >
          <PencilSquareIcon className="w-4" />
        </button>
        <button
          type="button"
          className="text-red-700"
          onClick={() => {
            props.setDestinationData(row);
            props.setIsDeleteDestination(true);
          }}
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
    ),
  },
];

export const ResortColumns = (props) => [
  {
    name: "Destination",
    selector: (row) => row?.destination?.name,
    sortable: true,
    grap: 1,
  },
  {
    name: "Resort Name",
    selector: (row) => row?.name,
    sortable: true,
    grap: 1,
  },
  {
    name: "Description",
    selector: (row) => row?.description,
    sortable: true,
    grap: 1,
    maxWidth: "250px",
  },
  {
    name: "Address",
    selector: (row) => row?.address,
    sortable: true,
    grap: 1,
    maxWidth: "250px",
  },
  {
    name: "LatLong",
    selector: (row) => row?.latlong,
    sortable: true,
    grap: 1,
    maxWidth: "250px",
  },
  {
    action: "Action",
    gap: 1,
    cell: (row) => (
      <div
        key={row.destination_id}
        className="flex flex-row justify-center items-center gap-1"
      >
        <Link
          to={`/dashboard/resort/${row.resort_id}`}
          className="text-blue-700"
        >
          <EyeIcon className="w-4" />
        </Link>
        <button
          type="button"
          className="text-green-700"
          onClick={() => {
            props.setResortData(row);
            props.setIsUpdateResort(true);
          }}
        >
          <PencilSquareIcon className="w-4" />
        </button>
        <button
          type="button"
          className="text-red-700"
          onClick={() => {
            props.setResortData(row);
            props.setIsDeleteResort(true);
          }}
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
    ),
  },
];

export const AmenityColumns = (props) => [
  {
    name: "Name",
    selector: (row) => row?.name,
    sortable: true,
    grap: 1,
  },
  {
    name: "Has Charged?",
    selector: (row) => (
      <Toggle
        size="lg"
        checkedChildren="Yes"
        unCheckedChildren="No"
        defaultChecked={row?.is_charged ? true : false}
        onChange={async (checked, e) => {
          await props.setAmenityData(row);
          await props.toggleIsChargedHandler(checked, row);
        }}
      />
    ),
    sortable: true,
    grap: 1,
  },
  {
    action: "Action",
    gap: 1,
    cell: (row) => (
      <div
        key={row.destination_id}
        className="flex flex-row justify-center items-center gap-1"
      >
        <button
          type="button"
          className="text-green-700"
          onClick={() => {
            props.setAmenityData(row);
            props.setIsUpdateAmenity(true);
          }}
        >
          <PencilSquareIcon className="w-4" />
        </button>
        <button
          type="button"
          className="text-red-700"
          onClick={() => {
            props.setAmenityData(row);
            props.setIsDeleteAmenity(true);
          }}
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
    ),
  },
];

export const RoomColumns = (props) => [
  {
    name: "Name",
    selector: (row) => row?.name,
    sortable: true,
    grap: 1,
  },
  {
    name: "Description",
    selector: (row) => row?.description,
    sortable: true,
    grap: 1,
    maxWidth: "250px",
  },
  {
    action: "Action",
    gap: 1,
    cell: (row) => (
      <div
        key={row.destination_id}
        className="flex flex-row justify-center items-center gap-1"
      >
        <button
          type="button"
          className="text-green-700"
          onClick={async () => {
            await props.setRoomData(row);
            props.setIsUpdateRoom(true);
          }}
        >
          <PencilSquareIcon className="w-4" />
        </button>
        <button
          type="button"
          className="text-red-700"
          onClick={async () => {
            await props.setRoomData(row);
            props.setIsDeleteRoom(true);
          }}
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
    ),
  },
];

export const RoomItemColumns = (props) => [
  {
    name: "Name",
    selector: (row) => row?.name,
    sortable: true,
    grap: 1,
  },
  {
    name: "Has Charged?",
    selector: (row) => (
      <Toggle
        size="lg"
        checkedChildren="Yes"
        unCheckedChildren="No"
        defaultChecked={row?.is_charged ? true : false}
        onChange={async (checked, e) => {
          await props.setRoomItemData(row);
          await props.toggleIsChargedHandler(checked, row);
        }}
      />
    ),
    sortable: true,
    grap: 1,
  },
  {
    action: "Action",
    gap: 1,
    cell: (row) => (
      <div
        key={row.room_item_id}
        className="flex flex-row justify-center items-center gap-1"
      >
        <button
          type="button"
          className="text-blue-700"
          onClick={async () => {
            props.setRoomItemData(row);
            props.setIsUpdateRoomItem(true);
          }}
        >
          <PencilSquareIcon className="w-4" />
        </button>
        <button
          type="button"
          className="text-blue-700"
          onClick={async () => {
            props.setRoomItemData(row);
            props.setIsDeleteRoomItem(true);
          }}
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
    ),
  },
];

export const ResortPhotoColumns = (props) => [
  {
    name: "Photo",
    selector: (row) => <img src={row.photo_url} alt="" className="w-40" />,
    sortable: true,
    grap: 1,
  },
  {
    action: "Action",
    gap: 1,
    cell: (row) => (
      <div className="flex flex-row justify-center items-center gap-1">
        <button
          type="button"
          className="text-red-500"
          onClick={() => props.deletePhotoHandler(row.photo_id)}
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
    ),
  },
];

export const packageColumns = (props) => [
  {
    name: "Room (Resort)",
    selector: (row) => `${row?.room.name} (${row?.room.resort.name})`,
    sortable: true,
    gap: 1,
  },
  {
    name: "Package Name",
    selector: (row) => row?.name,
    sortable: true,
    gap: 1,
  },
  {
    name: "Short Description",
    selector: (row) => row?.short_description,
    sortable: true,
    gap: 1,
    maxWidth: "250px",
  },
  {
    name: "Description",
    selector: (row) => row?.description,
    sortable: true,
    gap: 1,
    maxWidth: "250px",
  },
  {
    name: "Duration",
    selector: (row) => `${row?.days} days`,
    sortable: true,
    gap: 1,
    maxWidth: "250px",
  },
  {
    name: "Price",
    selector: (row) => row?.price,
    sortable: true,
    gap: 1,
    maxWidth: "100px",
  },
  {
    name: "Discount",
    selector: (row) => row?.discount,
    sortable: true,
    gap: 1,
    maxWidth: "100px",
  },
  {
    name: "Adults",
    selector: (row) => row?.adults,
    sortable: true,
    gap: 1,
    maxWidth: "50px",
  },
  {
    name: "Children",
    selector: (row) => row?.children,
    sortable: true,
    maxWidth: "50px",
    gap: 1,
  },
  {
    name: "Infants",
    selector: (row) => row?.infants,
    sortable: true,
    gap: 1,
    maxWidth: "50px",
  },
  {
    action: "Action",
    gap: 1,
    cell: (row) => (
      <div
        key={row.package_id}
        className="flex gap-1 flex-row justify-center items-center"
      >
        <Link to="/" className="text-blue-700">
          <EyeIcon className="w-4" />
        </Link>
        <button
          type="button"
          className="text-green-700"
          onClick={async () => {
            await props.setData(row);
            await props.setIsUpdatePackage(true);
          }}
        >
          <PencilSquareIcon className="w-4" />
        </button>
        <button
          type="button"
          className="text-red-700"
          onClick={async () => {
            await props.setData(row);
            await props.setIsDeletePackage(true);
          }}
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
    ),
  },
];
