import { useEffect } from "react";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllResorts } from "../../../redux/actions/resort";

const SelectRoom = ({ selectRoom, resorts, getAllResorts }) => {
  useEffect(() => {
    getAllResorts();
  }, []);

  return (
    <div className="p-5">
      <p>Select Room:</p>
      {resorts.map((resort, index) => (
        <div className="border-b border-gray-200 pb-5" key={index}>
          <div className="text-black px-3 py-2 my-2 uppercase">
            {resort.name} <small>({resort.destination.name})</small>
          </div>
          <div className="flex gap-4">
            {resort.rooms.map((room, index) => (
              <button
                key={index}
                type="button"
                className="border border-gray-500 text-gray-500 px-3 py-2 rounded"
                onClick={() => selectRoom(room)}
              >
                {room.name}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

SelectRoom.propTypes = {
  getAllResorts: PropTypes.func.isRequired,
  resorts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  resorts: state.resortReducer.resorts,
});

export default connect(mapStateToProps, { getAllResorts })(SelectRoom);
