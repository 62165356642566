import { useState, useEffect } from "react";
import Routes from "./routers/index";
import Loader from "./components/Loader";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./redux/actions/auth";
import { LOGOUT } from "./redux/actions/types";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    // Call loadUser and wait for it to complete before setting loading to false
    store.dispatch(loadUser()).then(() => setLoading(false));

    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Provider store={store}>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <Routes />
      </div>
    </Provider>
  );
};

export default App;
