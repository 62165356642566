import { Navigate } from "react-router-dom";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, auth: { isAuthenticated } }) => {
  if (isAuthenticated) return <Component />;
  else return <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, {})(PrivateRoute);
