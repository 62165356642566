import { useState } from "react";
import AnyReactComponent from "../../components/AnyReactComponent/AnyReactComponent";
import StayCardH from "../../components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import ButtonClose from "../../shared/ButtonClose/ButtonClose";
import Checkbox from "../../shared/Checkbox/Checkbox";
import Pagination from "../../shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "../../components/Heading/Heading2";
import formatDateShort from "../../utils/formatDateShort";

const SectionGridHasMap = ({ searchResult, searchResult: { results } }) => {
  const [currentHoverID, setCurrentHoverID] = useState(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  const latlongString = results[0].room.resort.latlong;
  const [lat, lng] = latlongString
    .split(",")
    .map((coord) => parseFloat(coord.trim()));

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading={searchResult.destination}
            subHeading={
              <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                {searchResult.total_result} package
                {searchResult.total_result.length > 0 ? "s" : ""}
                <span className="mx-2">·</span>
                {formatDateShort(searchResult.searchParams.start_date)} -
                {formatDateShort(searchResult.searchParams.end_date)}
                <span className="mx-2">·</span>{" "}
                {searchResult.searchParams.total_guests}
                Guests
              </span>
            }
          />
          <div className="grid grid-cols-1 gap-8">
            {results.map((item) => (
              <div
                key={item.package_id}
                onMouseEnter={() => setCurrentHoverID((_) => item.package_id)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <StayCardH data={item} />
              </div>
            ))}
          </div>
          {/* <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div> */}
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
            <GoogleMapReact
              defaultZoom={12}
              defaultCenter={{ lat, lng }}
              bootstrapURLKeys={{
                key: "AIzaSyB2BW8rx615MZ2VLVRXrD1suJ7sU0Ibp84",
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              {results.map((item) => {
                const latlongStringItem = item.room.resort.latlong;
                const [latItem, lngItem] = latlongStringItem
                  .split(",")
                  .map((coord) => parseFloat(coord.trim()));
                return (
                  <AnyReactComponent
                    isSelected={currentHoverID === item.package_id}
                    key={item.package_id}
                    lat={latItem}
                    lng={lngItem}
                    listing={item}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
