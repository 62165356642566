import {
  createBrowserRouter,
  RouterProvider as Router,
} from "react-router-dom";
import useWindowSize from "../hooks/useWindowResize";

import Layout from "../layouts/dashboard/Layout";
import PrivateRoute from "./PrivateRoute";

import ScrollToTop from "./ScrollToTop";
import Footer from "../shared/Footer/Footer";
import SiteHeader from "../containers/SiteHeader";
import FooterNav from "../components/FooterNav";

import AdminIndexPage from "../containers/Dashboard/Index";
import AdminDestinationsPage from "../containers/Dashboard/Destinations";
import AdminResortsPage from "../containers/Dashboard/Resorts";
import AdminPackagesPage from "../containers/Dashboard/Packages";
import AdminViewResortPage from "../containers/Dashboard/ViewResort";

import PageHome from "../containers/PageHome/PageHome";
import ListingStayPage from "../containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "../containers/ListingStayPage/ListingStayMapPage";
import CheckOutPage from "../containers/CheckOutPage/CheckOutPage";
import PayPage from "../containers/PayPage/PayPage";
import PageContact from "../containers/PageContact/PageContact";
import PageAbout from "../containers/PageAbout/PageAbout";
import PageLogin from "../containers/PageLogin/PageLogin";
import ListingStayDetailPage from "../containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import Page404 from "../containers/Page404/Page404";

const DefaultLayout = ({ component: Component }) => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <>
      <ScrollToTop />
      <SiteHeader />
      <Component />
      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </>
  );
};

// Admin Page
const AdminIndex = () => {
  return (
    <Layout>
      <AdminIndexPage />
    </Layout>
  );
};

const AdminDestinations = () => {
  return (
    <Layout>
      <AdminDestinationsPage />
    </Layout>
  );
};

const AdminResorts = () => {
  return (
    <Layout>
      <AdminResortsPage />
    </Layout>
  );
};

const AdminPackages = () => {
  return (
    <Layout>
      <AdminPackagesPage />
    </Layout>
  );
};

const AdminViewResort = () => {
  return (
    <Layout>
      <AdminViewResortPage />
    </Layout>
  );
};

const pages = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout component={PageHome} />,
    errorElement: <Page404 />,
  },
  //
  {
    path: "/listing-stay",
    element: <DefaultLayout component={ListingStayPage} />,
  },
  {
    path: "/search-result",
    element: <DefaultLayout component={ListingStayMapPage} />,
  },
  {
    path: "/package/view/:slug",
    element: <DefaultLayout component={ListingStayDetailPage} />,
  },
  //
  { path: "/checkout", element: <DefaultLayout component={CheckOutPage} /> },
  { path: "/pay-done", element: <DefaultLayout component={PayPage} /> },

  //
  { path: "/contact", element: <DefaultLayout component={PageContact} /> },
  { path: "/about", element: <DefaultLayout component={PageAbout} /> },
  { path: "/admin-login", element: <DefaultLayout component={PageLogin} /> },
  { path: "/login", element: <DefaultLayout component={PageLogin} /> },
  {
    path: "/dashboard/index",
    element: <PrivateRoute component={AdminIndex} />,
  },
  {
    path: "/dashboard/destinations",
    element: <PrivateRoute component={AdminDestinations} />,
  },
  {
    path: "/dashboard/resorts",
    element: <PrivateRoute component={AdminResorts} />,
  },
  {
    path: "/dashboard/packages",
    element: <PrivateRoute component={AdminPackages} />,
  },
  {
    path: "dashboard/resort/:resortId",
    element: <PrivateRoute component={AdminViewResort} />,
  },
]);

const MyRoutes = () => {
  return <Router router={pages}></Router>;
};

export default MyRoutes;
