import { SET_CHECK_IN, SET_CHECK_OUT, SET_RESERVATION_PACKAGE } from "./types";

export const setStartDate = (date) => ({ type: SET_CHECK_IN, payload: date });

export const setEndDate = (date) => ({ type: SET_CHECK_OUT, payload: date });

export const setReservationPackage = (packageData) => ({
  type: SET_RESERVATION_PACKAGE,
  payload: packageData,
});
