import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "./types";
import api from "../../utils/api";
import setAuthToken from "../../utils/setAuthToken";
import { setError, removeError } from "./error";
import { setAlert, removeAllAlerts } from "./alert";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await api.get("user/me");

    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const login = (formData) => async (dispatch) => {
  dispatch(removeError());
  dispatch(removeAllAlerts());

  try {
    const res = await api.post("auth/login", formData);
    const response = res.data;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const error = err.response.data;

    dispatch({
      type: LOGIN_FAIL,
    });

    dispatch(setAlert(error.message, "danger"));

    if (error.data !== null) {
      dispatch(setError(error.data));
    }
  }
};

// Logout user
export const logout = () => ({ type: LOGOUT });
