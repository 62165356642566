import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "../../../components/ModalSelectDate";
import converSelectedDateToString from "../../../utils/converSelectedDateToString";
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";

import { connect } from "react-redux";

const MobileFooterSticky = ({ startDate, endDate, packageData }) => {
  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            ${packageData?.new_price}
          </span>
          <ModalSelectDate
            renderChildren={({ openModal }) => (
              <span
                // onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {converSelectedDateToString([startDate, endDate])}
              </span>
            )}
          />
        </div>
        <ModalReserveMobile
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              disabled={startDate && endDate ? false : true}
              onClick={openModal}
            >
              Reserve
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.reservationReducer.startDate,
  endDate: state.reservationReducer.endDate,
  packageData: state.reservationReducer.package,
});

export default connect(mapStateToProps)(MobileFooterSticky);
