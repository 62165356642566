const formatDate = (date) => {
  const utcTimestamp = new Date(date);

  const formattedDate = utcTimestamp
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/-/g, "");

  return formattedDate;
};

export default formatDate;
