import GallerySlider from "../../components/GallerySlider/GallerySlider";
import SaleOffBadge from "../../components/SaleOffBadge/SaleOffBadge";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const StayCard = ({ size = "default", className = "", data, loading }) => {
  const {
    package_id,
    name,
    short_description,
    price,
    discount,
    new_price,
    room,
    package_images,
    slug,
  } = data;

  const href = `/package/view/${slug}`;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${package_id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={package_images.slice(0, 5)}
          href={href}
        />
        {discount && (
          <SaleOffBadge
            className="absolute left-3 top-3"
            desc={`-${discount}%`}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="text-xs truncate">{room.resort.address}</span>
          </div>
        </div>
        <div className="p-2 truncate">
          <span className="text-xs">{short_description}</span>
        </div>
        <div>
          <span className="text-base font-semibold">
            <span className="line-through text-xs text-gray-400 block">
              ${price}
            </span>
            ${new_price} <span>USD</span>
          </span>
        </div>
      </div>
    );
  };

  return (
    !loading && (
      <div
        className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
        data-nc-id="StayCard"
      >
        {renderSliderGallery()}
        <a href={href}>{renderContent()}</a>
      </div>
    )
  );
};

StayCard.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.packageReducer.loading,
});

export default connect(mapStateToProps)(StayCard);
