import {
  SET_CHECK_IN,
  SET_CHECK_OUT,
  SET_RESERVATION_PACKAGE,
} from "../actions/types";

const initialState = {
  package: null,
  startDate: null,
  endDate: null,
};

const reservationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CHECK_IN:
      return {
        ...state,
        startDate: payload,
      };
    case SET_CHECK_OUT:
      return {
        ...state,
        endDate: payload,
      };
    case SET_RESERVATION_PACKAGE:
      return {
        ...state,
        package: payload,
      };
    default:
      return state;
  }
};

export default reservationReducer;
