export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REMOVE_ALL_ALERTS = "REMOVE_ALL_ALERTS";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_ERROR = "SET_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";

export const GET_DESTINATIONS = "GET_DESTINATIONS";
export const GET_DESTINATION = "GET_DESTINATION";
export const ADD_DESTINATION = "ADD_DESTINATION";
export const UPDATE_DESTINATION = "UPDATE_DESTINATION";
export const DELETE_DESTINATION = "DELETE_DESTINATION";
export const DESTINATION_ERROR = "DESTINATION_ERROR";

export const GET_RESORTS = "GET_RESORTS";
export const GET_RESORT = "GET_RESORT";
export const ADD_RESORT = "ADD_RESORT";
export const ADD_RESORT_PHOTOS = "ADD_RESORT_PHOTOS";
export const UPDATE_RESORT = "UPDATE_RESORT";
export const DELETE_RESORT = "DELETE_RESORT";
export const RESORT_ERROR = "RESORT_ERROR";

export const GET_ROOMS = "GET_ROOMS";
export const GET_ROOM = "GET_ROOM";
export const ADD_ROOM = "ADD_ROOM";
export const ADD_ROOM_PHOTOS = "ADD_ROOM_PHOTOS";
export const UPDATE_ROOM = "UPDATE_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";
export const ROOM_ERROR = "ROOM_ERROR";

export const GET_AMENITIES = "GET_AMENITIES";
export const GET_AMENITY = "GET_AMENITY";
export const ADD_AMENITY = "ADD_AMENITY";
export const UPDATE_AMENITY = "UPDATE_AMENITY";
export const DELETE_AMENITY = "DELETE_AMENITY";
export const AMENITY_ERROR = "AMENITY_ERROR";

export const GET_ROOM_ITEMS = "GET_ROOM_ITEMS";
export const GET_ROOM_ITEM = "GET_ROOM_ITEM";
export const ADD_ROOM_ITEM = "ADD_ROOM_ITEM";
export const UPDATE_ROOM_ITEM = "UPDATE_ROOM_ITEM";
export const DELETE_ROOM_ITEM = "DELETE_ROOM_ITEM";
export const ROOM_ITEM_ERROR = "ROOM_ITEM_ERROR";

export const GET_ALL_PACKAGES = "GET_ALL_PACKAGES";
export const GET_PACKAGE = "GET_PACKAGE";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const PACKAGE_ERROR = "PACKAGE_ERROR";
export const PACKAGE_LOADING = "PACKAGE_LOADING";

export const SET_IMAGES = "SET_IMAGES";

export const GET_ALL_SCHEDULES = "GET_ALL_SCHEDULES";
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const SCHEDULE_ERROR = "SCHEDULE_ERROR";

export const SET_CHECK_IN = "SET_CHECK_IN";
export const SET_CHECK_OUT = "SET_CHECK_OUT";
export const SET_RESERVATION_PACKAGE = "SET_RESERVATION_PACKAGE";

export const SET_SEARCH_LOCATION = "SET_SEARCH_LOCATION";
export const SET_SEARCH_GUESTS = "SET_SEARCH_GUESTS";
export const SET_SEARCH_CHECK_IN = "SET_SEARCH_CHECK_IN";
export const SET_SEARCH_CHECK_OUT = "SET_SEARCH_CHECK_OUT";
export const RESET_SEARCH = "RESET_SEARCH";
