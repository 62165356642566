import { useRef, useEffect } from "react";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateResort } from "../../../redux/actions/resort";
import { getAllDestinations } from "../../../redux/actions/destination";

// Formik & Yup
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Components
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import Alert from "../../../components/Alert/Alert";

const validationSchema = Yup.object().shape({
  destination_id: Yup.number().required("Destination ID is required"),
  name: Yup.string().required("Resort name is required"),
  description: Yup.string().required("Description is required"),
  address: Yup.string().required("Address is required"),
  latlong: Yup.string().required("LatLong (Latitude, Longitude) is required"),
});

const UpdateResortForm = ({
  cancelButtonRef,
  setShowModal,
  errors,
  updateResort,
  getAllDestinations,
  destinations,
  resort,
}) => {
  const formikRef = useRef(null);

  const initialValues = {
    destination_id: resort.destination_id,
    name: resort.name,
    description: resort.description,
    address: resort.address,
    latlong: resort.latlong,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    await updateResort(values, resort.resort_id);
    setSubmitting(false);
  };

  useEffect(() => {
    formikRef.current.setErrors(errors);
  }, [errors, formikRef]);

  useEffect(() => {
    getAllDestinations();
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        values,
      }) => (
        <Form className="grid grid-cols-1 gap-6 px-5 py-5">
          <Alert />
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.destination_id &&
                touched.destination_id &&
                "text-red-500 dark:text-red-500"
              }`}
            >
              Destination
            </span>
            <select
              name="destination_id"
              className={`mt-1 ${
                errors.destination_id &&
                touched.destination_id &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              value={values.destination_id}
              onChange={(e) => {
                setFieldValue("destination_id", e.target.value);
                setFieldTouched("destination_id", true, false);
              }}
            >
              <option value="">-- select --</option>
              {destinations &&
                destinations.map((destination) => (
                  <option
                    key={destination.destination_id}
                    value={destination.destination_id}
                  >
                    {destination.name}
                  </option>
                ))}
            </select>
            {errors.destination_id && touched.destination_id && (
              <div className="text-red-500 text-sm font-bold">
                {errors.destination_id}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.name && touched.name && "text-red-500 dark:text-red-500"
              }`}
            >
              Resort Name
            </span>
            <Field
              type="text"
              name="name"
              className={`mt-1 ${
                errors.name &&
                touched.name &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
                setFieldTouched("name", true, false);
              }}
            />
            {errors.name && touched.name && (
              <div className="text-red-500 text-sm font-bold">
                {errors.name}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.description &&
                touched.description &&
                "text-red-500 dark:text-red-500"
              }`}
            >
              Description
            </span>
            <textarea
              name="description"
              rows="6"
              className={`mt-1 ${
                errors.description &&
                touched.description &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              value={values.description}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
                setFieldTouched("description", true, false);
              }}
            />
            {errors.description && touched.description && (
              <div className="text-red-500 text-sm font-bold">
                {errors.description}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.address &&
                touched.address &&
                "text-red-500 dark:text-red-500"
              }`}
            >
              Address
            </span>
            <Field
              type="text"
              name="address"
              className={`mt-1 ${
                errors.address &&
                touched.address &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("address", e.target.value);
                setFieldTouched("address", true, false);
              }}
            />
            {errors.address && touched.address && (
              <div className="text-red-500 text-sm font-bold">
                {errors.address}
              </div>
            )}
          </label>
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.latitude &&
                touched.latitude &&
                "text-red-500 dark:text-red-500"
              }`}
            >
              Latitude, Longtitude
            </span>
            <Field
              type="text"
              name="latlong"
              className={`mt-1 ${
                errors.latlong &&
                touched.latlong &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("latlong", e.target.value);
                setFieldTouched("latlong", true, false);
              }}
            />
            {errors.latlong && touched.latlong && (
              <div className="text-red-500 text-sm font-bold">
                {errors.latlong}
              </div>
            )}
          </label>
          <div className="flex justify-end gap-2">
            <ButtonPrimary type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Updating" : "Update"}
            </ButtonPrimary>
            <ButtonPrimary
              type="button"
              className="bg-red-500 hover:bg-red-400"
              onClick={() => setShowModal(false)}
              ref={cancelButtonRef}
            >
              Cancel
            </ButtonPrimary>
          </div>
        </Form>
      )}
    </Formik>
  );
};

UpdateResortForm.propTypes = {
  errors: PropTypes.array.isRequired,
  updateResort: PropTypes.func.isRequired,
  getAllDestinations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errorReducer,
  destinations: state.destinationReducer.destinations,
});

export default connect(mapStateToProps, { updateResort, getAllDestinations })(
  UpdateResortForm
);
