import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import NcModal from "../../shared/NcModal/NcModal";
import ModalSelectDate from "../../components/ModalSelectDate";
import converSelectedDateToString from "../../utils/converSelectedDateToString";
import ModalSelectGuests from "../../components/ModalSelectGuests";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import api from "../../utils/api";
import formatDate from "../../utils/formatDate";

const validationSchema = Yup.object().shape({
  package: Yup.object().shape(),
  payment_intent_id: Yup.string(),
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  check_in: Yup.string().required(),
  check_out: Yup.string().required(),
});

const CheckOutForm = ({ packageData, reservation, startDate, endDate }) => {
  const navigate = useNavigate();

  const formikRef = useRef(null);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);

  const stripePaymentOptions = {
    layout: "tabs",
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intense_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded");
          break;
        case "processing":
          setMessage("Your payment is processing");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const initialValues = {
    package: packageData,
    payment_intent_id: "",
    name: "",
    email: "",
    phone: "",
    check_in: formatDate(startDate),
    check_out: formatDate(endDate),
  };

  const onSubmit = async (values, { setSubmitting }) => {
    if (!stripe || !elements) {
      return;
    }

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_MAIN_URL,
      },
      redirect: "if_required",
    });

    if (response.error) {
      const { error } = response;
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured");
      }
    } else {
      const paymentIntent = response.paymentIntent;

      if (paymentIntent.status === "succeeded") {
        values.payment_intent_id = await paymentIntent.id;

        await api.post("/reservation", values).then((res) => {
          const response = res.data;
          localStorage.setItem(
            "reservation_success",
            JSON.stringify(response.data)
          );
          return navigate("/pay-done");
        });
      }
    }

    setSubmitting(false);
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={reservation.package.featured_img_url}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {reservation.package.short_description}
              </span>
              <span className="text-base font-medium mt-1 block">
                {reservation.package.name}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {reservation.package.days > 1 ? (
                <>
                  {reservation.package.days} Days and{" "}
                  {reservation.package.days - 1} Nights
                </>
              ) : (
                <>{reservation.package.days} Day</>
              )}
            </span>
            <span>${reservation.package.new_price}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>${reservation.package.new_price}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
      <h2 className="text-3xl lg:text-4xl font-semibold">
        Confirm and Checkout
      </h2>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({
          errors,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          touched,
        }) => (
          <Form className="w-full flex flex-col space-y-8" id="payment-form">
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div>
              <div>
                <h3 className="text-2xl font-semibold">Your trip</h3>
                <NcModal
                  renderTrigger={(openModal) => (
                    <span
                      onClick={() => openModal()}
                      className="block lg:hidden underline  mt-1 cursor-pointer"
                    >
                      View booking details
                    </span>
                  )}
                  renderContent={renderSidebar}
                  modalTitle="Booking details"
                />
              </div>
              <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
                <ModalSelectDate
                  renderChildren={({ openModal }) => (
                    <div
                      className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                      type="button"
                    >
                      <div className="flex flex-col">
                        <span className="text-sm text-neutral-400">Date</span>
                        <span className="mt-1.5 text-lg font-semibold">
                          {converSelectedDateToString([startDate, endDate])}
                        </span>
                      </div>
                    </div>
                  )}
                />

                <ModalSelectGuests
                  renderChildren={({ openModal }) => (
                    <div className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800">
                      <div className="flex flex-col">
                        <span className="text-sm text-neutral-400">Guests</span>
                        <span className="mt-1.5 text-lg font-semibold">
                          <span className="line-clamp-1">
                            {`${reservation.package.adults} Adults, ${reservation.package.children} Children, ${reservation.package.infants} Infants`}
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="flex flex-col space-y-8">
              <label className="block">
                <span
                  className={`text-neutral-800 dark:text-neutral-200 ${
                    errors.name &&
                    touched.name &&
                    "text-red-500 dark:text-red-500"
                  }`}
                >
                  Your Name
                </span>
                <Field
                  type="text"
                  name="name"
                  placeholder=""
                  className={`mt-1 rounded-2xl ${
                    errors.name &&
                    touched.name &&
                    "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
                  }`}
                  onChange={(e) => {
                    setFieldValue("name", e.target.value);
                    setFieldTouched("name", true, false);
                  }}
                />
                {errors.name && touched.name && (
                  <div className="text-red-500 text-sm font-bold">
                    {errors.name}
                  </div>
                )}
              </label>
              <label className="block">
                <span
                  className={`text-neutral-800 dark:text-neutral-200 ${
                    errors.email &&
                    touched.email &&
                    "text-red-500 dark:text-red-500"
                  }`}
                >
                  Email
                </span>
                <Field
                  type="email"
                  name="email"
                  placeholder=""
                  className={`mt-1 rounded-2xl ${
                    errors.email &&
                    touched.email &&
                    "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
                  }`}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    setFieldTouched("email", true, false);
                  }}
                />
                {errors.email && touched.email && (
                  <div className="text-red-500 text-sm font-bold">
                    {errors.email}
                  </div>
                )}
              </label>
              <label className="block">
                <span
                  className={`text-neutral-800 dark:text-neutral-200 ${
                    errors.phone &&
                    touched.phone &&
                    "text-red-500 dark:text-red-500"
                  }`}
                >
                  Phone
                </span>
                <Field
                  type="text"
                  name="phone"
                  placeholder=""
                  className={`mt-1 rounded-2xl ${
                    errors.phone &&
                    touched.phone &&
                    "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
                  }`}
                  onChange={(e) => {
                    setFieldValue("phone", e.target.value);
                    setFieldTouched("phone", true, false);
                  }}
                />
                {errors.phone && touched.phone && (
                  <div className="text-red-500 text-sm font-bold">
                    {errors.phone}
                  </div>
                )}
              </label>
            </div>

            <div>
              <h3 className="text-2xl font-semibold">Pay with</h3>
              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

              <div className="mt-6">
                <PaymentElement
                  id="payment-element"
                  options={stripePaymentOptions}
                />
                <div className="pt-8">
                  {message && <div className="mb-5">{message}</div>}
                  <ButtonPrimary type="Submit" loading={isSubmitting}>
                    Checkout
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CheckOutForm;
