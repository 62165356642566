import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const PackageLoading = () => {
  const numberOfSkeletons = 4;

  return (
    <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {Array.from({ length: numberOfSkeletons }).map((_, index) => (
        <div
          key={index}
          className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow py-2"
          data-nc-id="StayCard"
        >
          <div className="block dark:hidden">
            <Skeleton className="h-40 rounded-2xl" />
          </div>
          <div className="hidden dark:block">
            <SkeletonTheme baseColor="#1f2937" highlightColor="#111827">
              <Skeleton className="h-40 rounded-2xl" />
            </SkeletonTheme>
          </div>
          <div className="px-2">
            <div className="block dark:hidden">
              <Skeleton className="h-10 my-2" />
              <Skeleton className="h-10 my-2" />
              <Skeleton className="h-10 my-2" />
            </div>
            <div className="hidden dark:block">
              <SkeletonTheme baseColor="#1f2937" highlightColor="#111827">
                <Skeleton className="h-10 my-2" />
                <Skeleton className="h-10 my-2" />
                <Skeleton className="h-10 my-2" />
              </SkeletonTheme>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
