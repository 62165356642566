import {
  GET_ALL_PACKAGES,
  GET_PACKAGE,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
  PACKAGE_ERROR,
  PACKAGE_LOADING,
} from "../actions/types";

const initialState = {
  packages: [],
  package: null,
  loading: true,
  error: null,
};

const packageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PACKAGES:
      return {
        ...state,
        packages: payload,
        loading: false,
      };
    case GET_PACKAGE:
    case ADD_PACKAGE:
    case UPDATE_PACKAGE:
    case DELETE_PACKAGE:
      return {
        ...state,
        package: payload,
        loading: false,
      };
    case PACKAGE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case PACKAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default packageReducer;
