import { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../redux/actions/auth";

// Components
import Alert from "../../components/Alert/Alert";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const PageLogin = ({ className = "", login, isAuthenticated, errors }) => {
  const formikRef = useRef(null);

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values, { setSubmitting }) => {
    await login(values);
    setSubmitting(false);
  };

  if (isAuthenticated) {
    window.location.href = "/dashboard/index";
  }

  useEffect(() => {
    formikRef.current.setErrors(errors);
  }, [formikRef, errors]);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              errors,
              touched,
            }) => (
              <Form className="grid grid-cols-1 gap-6">
                <Alert />
                <label className="block">
                  <span
                    className={`text-neutral-800 dark:text-neutral-200 ${
                      errors.email &&
                      touched.email &&
                      "text-red-500 dark:text-red-500"
                    }`}
                  >
                    Email address
                  </span>
                  <Field
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                    className={`mt-1 ${
                      errors.email &&
                      touched.email &&
                      "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
                    }`}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                      setFieldTouched("email", true, false);
                    }}
                  />
                  {errors.email && touched.email && (
                    <div className="text-red-500 text-sm font-bold">
                      {errors.email}
                    </div>
                  )}
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    <span
                      className={`text-neutral-800 dark:text-neutral-200 ${
                        errors.password &&
                        touched.password &&
                        "text-red-500 dark:text-red-500"
                      }`}
                    >
                      Password
                    </span>
                    <Link to="/forgot-pass" className="text-sm">
                      Forgot password?
                    </Link>
                  </span>
                  <Field
                    type="password"
                    name="password"
                    className={`mt-1 ${
                      errors.password &&
                      touched.password &&
                      "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
                    }`}
                    onChange={(e) => {
                      setFieldValue("password", e.target.value);
                      setFieldTouched("password", true, false);
                    }}
                  />
                  {errors.password && touched.password && (
                    <div className="text-red-500 text-sm font-bold">
                      {errors.password}
                    </div>
                  )}
                </label>
                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  Login
                </ButtonPrimary>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

PageLogin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  errors: state.errorReducer,
});

export default connect(mapStateToProps, { login })(PageLogin);
