import DatePickerCustomDay from "../../components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "../../components/DatePickerCustomHeaderTwoMonth";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllSchedulesByPackage } from "../../redux/actions/schedule";
import { setStartDate, setEndDate } from "../../redux/actions/reservation";

const SectionDateRange = ({
  schedules,
  days,
  package_id,
  getAllSchedulesByPackage,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const [availableDates, setAvailableDates] = useState([]);

  const filterAvailableDates = (date) => {
    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for comparison

    // Conver date to string in the format 'yyyy-mm-dd' to match availableDates format
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

    // Check if the date is greater than or equal to today
    return date >= today && availableDates.includes(formattedDate);
  };

  const handleStartDateChange = (date) => {
    // Calculate the end date based on the selected start date and number of days
    const calculatedEndDate = new Date(date);
    calculatedEndDate.setDate(calculatedEndDate.getDate() + (days - 1));

    // Check if all dates in the range are available
    const isRangeAvailable = checkIfRangeAvailable(date, calculatedEndDate);

    // You can set some state here to handle the availability status
    // For example, setCanReserve(isRangeAvailable);
    if (isRangeAvailable) {
      setStartDate(date);
      setEndDate(calculatedEndDate);
    }
  };

  const checkIfRangeAvailable = (start, end) => {
    for (
      let currentDate = new Date(start);
      currentDate <= end;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const formattedDate = `${currentDate.getFullYear()}-${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      if (!availableDates.includes(formattedDate)) {
        // If any date in the range is not available, return false
        return false;
      }
    }

    // All dates in the range are available
    return true;
  };

  useEffect(() => {
    setAvailableDates(schedules);
  }, [schedules]);

  useEffect(() => {
    getAllSchedulesByPackage(package_id);
  }, [package_id]);

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Please select your check in/out date on our available schedules
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="">
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsStart
            monthsShown={2}
            showPopperArrow={false}
            inline
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
            filterDate={filterAvailableDates}
          />
        </div>
      </div>
    );
  };

  return renderSectionCheckIndate();
};

SectionDateRange.propTypes = {
  schedules: PropTypes.array.isRequired,
  getAllSchedulesByPackage: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  schedules: state.scheduleReducer.schedules,
  startDate: state.reservationReducer.startDate,
  endDate: state.reservationReducer.endDate,
});

export default connect(mapStateToProps, {
  getAllSchedulesByPackage,
  setStartDate,
  setEndDate,
})(SectionDateRange);
