import api from "../../utils/api";

// Delete photo
export const deletePhoto = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/photo/${id}`);
    const response = res.data;

    console.log(response);
  } catch (err) {
    const response = err.response.data;

    console.log(response);
  }
};

// Delete multiple photos
export const deleteMultiplePhotos = (formData) => async (dispatch) => {
  const res = await api.post("/photo/delete-multiple", formData);
  const response = res.data;

  console.log(response);
  try {
  } catch (err) {
    const response = err.response.data;

    console.log(response);
  }
};
