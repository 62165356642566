import {
  GET_RESORT,
  GET_RESORTS,
  ADD_RESORT,
  ADD_RESORT_PHOTOS,
  UPDATE_RESORT,
  DELETE_RESORT,
  RESORT_ERROR,
} from "../actions/types";

const initialState = {
  resorts: [],
  resort: null,
  photos: [],
  loading: true,
  error: null,
};

const resortReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RESORTS:
      return {
        ...state,
        resorts: payload,
        loading: false,
      };
    case DELETE_RESORT:
    case ADD_RESORT:
    case UPDATE_RESORT:
    case GET_RESORT:
      return {
        ...state,
        resort: payload,
        loading: false,
      };
    case ADD_RESORT_PHOTOS:
      return {
        ...state,
        photos: payload,
        loading: false,
      };
    case RESORT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default resortReducer;
