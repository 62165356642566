import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllDestinations } from "../../redux/actions/destination";
import { setLocationInputTo } from "../../redux/actions/search";

const LocationInput = ({
  onChange = () => {},
  className = "",
  defaultValue = "United States",
  headingText = "Where to?",
  destinations,
  getAllDestinations,
  setLocationInputTo,
}) => {
  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSelectLocation = (item) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item);
      onChange && onChange(item);
    }, 0);
    setLocationInputTo(item);
  };

  useEffect(() => {
    getAllDestinations();
  }, []);

  const renderSearchValues = ({ heading, items = destinations }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items
            .filter((item) =>
              item.name.toLowerCase().includes(value.toLocaleLowerCase())
            )
            .map((item) => {
              return (
                <div
                  className="py-2 mb-1 flex items-center space-x-3 text-sm"
                  onClick={() => handleSelectLocation(item.name)}
                  key={item.id}
                >
                  <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                  <span className="">{item.name}</span>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Search destinations"}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {value &&
            renderSearchValues({
              heading: "Search result",
            })}
        </div>
      </div>
    </div>
  );
};

LocationInput.propTypes = {
  getAllDestinations: PropTypes.func.isRequired,
  destinations: PropTypes.array.isRequired,
  setLocationInputTo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  destinations: state.destinationReducer.destinations,
});

export default connect(mapStateToProps, {
  getAllDestinations,
  setLocationInputTo,
})(LocationInput);
