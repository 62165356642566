import { useState, useEffect } from "react";
import ImageCard from "./ImageCard";
import { Container } from "./Container";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getResort } from "../../redux/actions/resort";
import { deleteMultiplePhotos } from "../../redux/actions/photo";

const Photos = ({ photos, resort, getResort, deleteMultiplePhotos }) => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  // Delete Selected Items
  const deleteHandler = async () => {
    await deleteMultiplePhotos({ photos: selectedImages });
    await getResort(resort.resort_id);
    setSelectedImages([]);
  };

  useEffect(() => {
    setGalleryImages(photos);
  }, [setGalleryImages, photos]);

  return (
    <Container>
      <div className="bg-white py-8">
        <div className="flex items-center justify-between px-4 pb-4">
          {selectedImages && selectedImages.length > 0 ? (
            <div className="flex gap-2 items-center justify-center">
              <input
                type="checkbox"
                checked={selectedImages.length > 0}
                onChange={() => {}}
                className="checked:bg-blue-700"
              />
              <p className="">
                <span>{selectedImages?.length}</span> file Selected
              </p>
            </div>
          ) : (
            ""
          )}

          {selectedImages && selectedImages.length > 0 && (
            <button
              className="text-xs text-red-500 hover:underline border border-red-500 px-3 py-1"
              onClick={deleteHandler}
            >
              Delete Photos
            </button>
          )}
        </div>

        <div className="px-6">
          <div
            className={`pt-4
                    grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-5 auto-rows-1
                    overflow-hidden
                  `}
          >
            {galleryImages && galleryImages.length === 0 && (
              <p className="text-gray-400">no photos</p>
            )}
            {galleryImages &&
              galleryImages.map((data, index) => (
                <div className="col-span-1 row-span-1" key={index}>
                  <ImageCard
                    data={data}
                    isSelected={selectedImages.includes(data)}
                    onImageSelect={() => {
                      if (selectedImages.includes(data)) {
                        setSelectedImages((prev) =>
                          prev.filter((item) => item !== data)
                        );
                      } else {
                        setSelectedImages((prev) => [...prev, data]);
                      }
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

Photos.propTypes = {
  getResort: PropTypes.func.isRequired,
  deleteMultiplePhotos: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getResort, deleteMultiplePhotos })(
  Photos
);
