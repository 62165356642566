import React from "react";
import ReactDOM from "react-dom/client";
import "rc-slider/assets/index.css";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "react-loading-skeleton/dist/skeleton.css";
import "rsuite/dist/rsuite-no-reset.min.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
