import { combineReducers } from "redux";
import authReducer from "./auth";
import errorReducer from "./error";
import alertReducer from "./alert";
import destinationReducer from "./destination";
import resortReducer from "./resort";
import amenityReducer from "./amenity";
import roomReducer from "./room";
import roomItemReducer from "./roomItem";
import packageReducer from "./package";
import imageReducer from "./image";
import scheduleReducer from "./schedule";
import reservationReducer from "./reservation";
import searchReducer from "./search";

const rootReducer = combineReducers({
  authReducer,
  errorReducer,
  alertReducer,
  destinationReducer,
  resortReducer,
  amenityReducer,
  roomReducer,
  roomItemReducer,
  packageReducer,
  imageReducer,
  scheduleReducer,
  reservationReducer,
  searchReducer,
});

export default rootReducer;
