import {
  GET_DESTINATIONS,
  GET_DESTINATION,
  ADD_DESTINATION,
  UPDATE_DESTINATION,
  DELETE_DESTINATION,
  DESTINATION_ERROR,
} from "./types";
import api from "../../utils/api";
import { setAlert, removeAllAlerts } from "./alert";
import { setError, removeError } from "./error";

// Get all destinations
export const getAllDestinations = () => async (dispatch) => {
  try {
    const res = await api.get("/destination");
    const response = res.data;

    dispatch({
      type: GET_DESTINATIONS,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    dispatch({
      type: DESTINATION_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Get destination
export const getDestination = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/destination/${id}`);
    const response = res.data;

    dispatch({
      type: GET_DESTINATION,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    dispatch({
      type: DESTINATION_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Add destination
export const addDestination = (formData) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());

  try {
    const res = await api.post("/destination", formData);
    const response = res.data;

    dispatch({
      type: ADD_DESTINATION,
      payload: response.data,
    });

    dispatch(getAllDestinations());

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }
    dispatch({
      type: DESTINATION_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Update destination
export const updateDestination = (formData, id) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());
  try {
    const res = await api.put(`/destination/${id}`, formData);
    const response = res.data;

    dispatch({
      type: UPDATE_DESTINATION,
      payload: response.data,
    });

    dispatch(getAllDestinations());

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }
    dispatch({
      type: DESTINATION_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Delete destination
export const deleteDestination = (id) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());

  try {
    const res = await api.delete(`/destination/${id}`);
    const response = res.data;

    dispatch({
      type: DELETE_DESTINATION,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));

    dispatch(getAllDestinations());
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }
    dispatch({
      type: DESTINATION_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};
