import { useEffect, useState } from "react";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "../../components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "../../components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "../../components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "../../components/SectionSliderNewCategories/SectionSliderNewCategories.js";
import SectionSubscribe2 from "../../components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const ListingStayMapPage = ({ className = "" }) => {
  const [searchResult, setSearchResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const result = localStorage.getItem("search_result");
    setSearchResult(JSON.parse(result));
    setLoading(false);

    if (!result) {
      window.location.href = "/";
    }
  }, []);

  return (
    !loading &&
    searchResult && (
      <div
        className={`nc-ListingStayMapPage relative ${className}`}
        data-nc-id="ListingStayMapPage"
      >
        <Helmet>
          <title>Search Result</title>
        </Helmet>
        <BgGlassmorphism />

        {searchResult.results.length > 0 ? (
          <>
            <div className="container pt-10 pb-24 lg:pt-16 lg:pb-28">
              <SectionHeroArchivePage
                destination={searchResult?.destination}
                total_result={searchResult?.total_result}
                currentPage="Stays"
                currentTab="Stays"
              />
            </div>

            <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
              <SectionGridHasMap searchResult={searchResult} />
            </div>
          </>
        ) : (
          <div className="container min-h-screen w-full text-center rounded-2xl px-5 py-20 justify-center items-center flex">
            <div>
              <p className="text-2xl font-bold text-gray-500 dark:text-white block">
                no result found
              </p>
              <a href="/" className="text-blue-500 block">
                Back to home
              </a>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default ListingStayMapPage;
