import { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import DataTable from "react-data-table-component";
import { packageColumns } from "../../constants/columns";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllPackages } from "../../redux/actions/package";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import AddPackage from "../../components/Package/AddPackage/AddPackage";
import UpdatePackage from "../../components/Package/UpdatePackage/UpdatePackage";
import DeletePackage from "../../components/Package/DeletePackage/DeletePackage";

const Packages = ({ getAllPackages, packages, loading }) => {
  const [isAddPackage, setIsAddPackage] = useState(false);
  const [isUpdatePackage, setIsUpdatePackage] = useState(false);
  const [isDeletePackage, setIsDeletePackage] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    getAllPackages();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <p className="card-title">Package List</p>
          <button
            type="button"
            className="text-blue-500"
            onClick={() => setIsAddPackage(true)}
          >
            <PlusCircleIcon className="w-6" />
          </button>
        </div>
      </div>
      <DataTable
        columns={packageColumns({
          setIsUpdatePackage,
          setIsDeletePackage,
          setData,
        })}
        data={packages}
        progressPending={loading}
        progressComponent={<LoadingSpinner />}
        pagination
        paginationServer
        dense
      />
      <AddPackage showModal={isAddPackage} setShowModal={setIsAddPackage} />
      <UpdatePackage
        showModal={isUpdatePackage}
        setShowModal={setIsUpdatePackage}
        packageData={data}
      />
      <DeletePackage
        showModal={isDeletePackage}
        setShowModal={setIsDeletePackage}
        packageData={data}
      />
    </div>
  );
};

Packages.propTypes = {
  getAllPackages: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  packages: state.packageReducer.packages,
  loading: state.packageReducer.loading,
});

export default connect(mapStateToProps, { getAllPackages })(Packages);
