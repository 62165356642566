import { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import DataTable from "react-data-table-component";
import { ResortColumns } from "../../constants/columns";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllResorts } from "../../redux/actions/resort";

// Components
import AddResort from "../../components/Resort/AddResort/AddResort";
import UpdateResort from "../../components/Resort/UpdateResort/UpdateResort";
import DeleteResort from "../../components/Resort/DeleteResort/DeleteResort";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

const Resorts = ({ resorts, getAllResorts, loading }) => {
  const [data, setData] = useState([]);
  const [isAddResort, setIsAddResort] = useState(false);
  const [isUpdateResort, setIsUpdateResort] = useState(false);
  const [isDeleteResort, setIsDeleteResort] = useState(false);
  const [resortData, setResortData] = useState({});

  useEffect(() => {
    getAllResorts();
  }, []);

  useEffect(() => {
    setData(resorts);
  }, [resorts, setData]);

  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <p className="card-title">Resort List</p>
          <button
            type="button"
            className="text-blue-500"
            onClick={() => setIsAddResort(true)}
          >
            <PlusCircleIcon className="w-6" />
          </button>
        </div>
      </div>
      <DataTable
        columns={ResortColumns({
          setResortData,
          setIsUpdateResort,
          setIsDeleteResort,
        })}
        data={data}
        progressPending={loading}
        progressComponent={<LoadingSpinner />}
        pagination
        paginationServer
        dense
        highlightOnHover
        pointerOnHover
      />
      <AddResort showModal={isAddResort} setShowModal={setIsAddResort} />
      <UpdateResort
        showModal={isUpdateResort}
        setShowModal={setIsUpdateResort}
        resort={resortData}
      />
      <DeleteResort
        showModal={isDeleteResort}
        setShowModal={setIsDeleteResort}
        resort={resortData}
      />
    </div>
  );
};

Resorts.propTypes = {
  getAllResorts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  resorts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  resorts: state.resortReducer.resorts,
  loading: state.resortReducer.loading,
});

export default connect(mapStateToProps, { getAllResorts })(Resorts);
