import {
  GET_RESORT,
  GET_RESORTS,
  ADD_RESORT,
  ADD_RESORT_PHOTOS,
  UPDATE_RESORT,
  DELETE_RESORT,
  RESORT_ERROR,
} from "./types";
import api from "../../utils/api";
import { setAlert, removeAllAlerts } from "./alert";
import { setError, removeError } from "./error";

// Get all resorts
export const getAllResorts = () => async (dispatch) => {
  try {
    const res = await api.get("/resort");
    const response = res.data;

    dispatch({
      type: GET_RESORTS,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }

    dispatch({
      type: RESORT_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Get resort
export const getResort = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/resort/${id}`);
    const response = (await res).data;

    dispatch({
      type: GET_RESORT,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }

    dispatch({
      type: RESORT_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Add resort
export const addResort = (formData) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());

  try {
    const res = await api.post("/resort", formData);
    const response = res.data;

    dispatch({
      type: ADD_RESORT,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));

    dispatch(getAllResorts());
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }

    dispatch({
      type: RESORT_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Update resort
export const updateResort = (formData, id) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());

  try {
    const res = await api.put(`/resort/${id}`, formData);
    const response = res.data;

    dispatch({
      type: UPDATE_RESORT,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));

    dispatch(getAllResorts());
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }

    dispatch({
      type: RESORT_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Delete resort
export const deleteResort = (id) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());

  try {
    const res = await api.delete(`/resort/${id}`);
    const response = res.data;

    dispatch({
      type: DELETE_RESORT,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));

    dispatch(getAllResorts());
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }

    dispatch({
      type: RESORT_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Add photos
export const addPhotos = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/resort/add-photos", formData);
    const response = res.data;

    dispatch({
      type: ADD_RESORT_PHOTOS,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    }

    dispatch({
      type: RESORT_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};
