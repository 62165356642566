import DatePickerCustomDay from "../DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "../DatePickerCustomHeaderTwoMonth";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAllSchedulesByResort,
  addSchedule,
} from "../../redux/actions/schedule";

const Schedules = ({
  resort,
  getAllSchedulesByResort,
  addSchedule,
  schedules,
}) => {
  const [availableDates, setAvailableDates] = useState([]);

  const customDateClass = (date) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    return availableDates.includes(formattedDate)
      ? "font-bold bg-green-500 text-white"
      : "text-gray-500";
  };

  const handleDateClick = (date) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

    const formData = {
      resort_id: resort?.resort_id,
      date: formattedDate,
    };

    addSchedule(formData);
  };

  useEffect(() => {
    setAvailableDates(schedules);
  }, [schedules]);

  useEffect(() => {
    getAllSchedulesByResort(resort?.resort_id);
  }, [resort]);

  return (
    <div className="card mt-5">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <p className="card-title">Schedules / Availability</p>
        </div>
      </div>
      <div className="card-body">
        <DatePicker
          onChange={handleDateClick}
          dayClassName={customDateClass}
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

Schedules.propTypes = {
  getAllSchedulesByResort: PropTypes.func.isRequired,
  addSchedule: PropTypes.func.isRequired,
  schedules: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  schedules: state.scheduleReducer.schedules,
});

export default connect(mapStateToProps, {
  getAllSchedulesByResort,
  addSchedule,
})(Schedules);
