import Button from "../../shared/Button/Button";

const ButtonPrimary = ({ className = "", ...props }) => {
  return (
    <Button
      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className}`}
      {...props}
    />
  );
};

export default ButtonPrimary;
