import {
  GET_AMENITIES,
  GET_AMENITY,
  ADD_AMENITY,
  UPDATE_AMENITY,
  DELETE_AMENITY,
  AMENITY_ERROR,
} from "../actions/types";

const initialState = {
  amenities: null,
  amenity: null,
  loading: true,
  error: null,
};

const amenityReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_AMENITIES:
      return {
        ...state,
        amenities: payload,
        loading: false,
      };
    case GET_AMENITY:
    case ADD_AMENITY:
    case UPDATE_AMENITY:
    case DELETE_AMENITY:
      return {
        ...state,
        amenity: payload,
        loading: false,
      };
    case AMENITY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default amenityReducer;
