import { useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { RoomItemColumns } from "../../../constants/columns";
import { Uploader, Button } from "rsuite";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Photos from "../../Photos/Photos";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateRoomItem } from "../../../redux/actions/roomItem";
import { getResort } from "../../../redux/actions/resort";
import { addPhotos } from "../../../redux/actions/room";

// Components
import AddRoomItem from "./AddRoomItem";
import UpdateRoomItem from "./UpdateRoomItem";
import DeleteRoomItem from "./DeleteRoomItem";

const RoomItems = ({ data: room, getResort, updateRoomItem, addPhotos }) => {
  const uploaderRef = useRef(null);
  const [newPhotos, setNewPhotos] = useState([]);
  const [isAddRoomItem, setIsAddRoomItem] = useState(false);
  const [isUpdateRoomItem, setIsUpdateRoomItem] = useState(false);
  const [isDeleteRoomItem, setIsDeleteRoomItem] = useState(false);
  const [roomItemData, setRoomItemData] = useState({});

  const toggleIsChargedHandler = (checked, row) => {
    updateRoomItem(row.room_item_id, {
      room_id: row.room_id,
      name: row.name,
      is_charged: checked,
    });
    getResort(room.resort_id);
  };

  const appendPhoto = (file) => {
    const newPhotosData = [...newPhotos, file];
    setNewPhotos(newPhotosData);
  };

  const addPhotoHandler = async () => {
    const photoItems = document.querySelectorAll(
      ".rs-uploader-file-item-picture"
    );

    await addPhotos({
      photos: newPhotos,
      room_id: room.room_id,
    });
    await getResort(room.resort_id);

    setNewPhotos([]);

    uploaderRef.current.defaultFileList = [];
    uploaderRef.current.fileList = [];

    for (const items of photoItems) {
      items.remove();
    }
  };

  return (
    <div className="bg-gray-50 border border-blue-200">
      <div className="card">
        <div className="card-header">
          <div className="flex justify-between items-center">
            <p className="text-gray-600 text-xs font-bold">Room Items</p>
            <button
              type="button"
              className="text-blue-500"
              onClick={() => setIsAddRoomItem(true)}
            >
              <PlusCircleIcon className="w-6" />
            </button>
          </div>
        </div>
        {room?.room_items && (
          <DataTable
            columns={RoomItemColumns({
              setIsUpdateRoomItem,
              setRoomItemData,
              setIsDeleteRoomItem,
              toggleIsChargedHandler,
            })}
            data={room.room_items}
          />
        )}
        <AddRoomItem
          showModal={isAddRoomItem}
          setShowModal={setIsAddRoomItem}
          resort_id={room.resort_id}
          room={room}
        />
        <UpdateRoomItem
          showModal={isUpdateRoomItem}
          setShowModal={setIsUpdateRoomItem}
          resort_id={room.resort_id}
          room={room}
          room_item={roomItemData}
        />
        <DeleteRoomItem
          showModal={isDeleteRoomItem}
          setShowModal={setIsDeleteRoomItem}
          resort_id={room.resort_id}
          room_item={roomItemData}
        />
      </div>
      <div className="card">
        <div className="card-header">
          <div className="flex justify-between items-center">
            <p className="text-gray-600 text-xs font-bold">Room Photos</p>
          </div>
        </div>
        <Photos resort={{ resort_id: room.resort_id }} photos={room.photos} />
        <div className="card-body">
          <div>
            <Uploader
              ref={uploaderRef}
              multiple
              listType="picture"
              action={`${process.env.REACT_APP_BACKEND_BASE_URL}/file/upload`}
              onSuccess={(res) => {
                appendPhoto(res.data);
              }}
            >
              <button>
                <CameraRetroIcon />
              </button>
            </Uploader>
            {newPhotos.length !== 0 && (
              <Button
                appearance="primary"
                className="w-16 h-16 -mt-14 ml-5"
                onClick={() => addPhotoHandler()}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

RoomItems.propTypes = {
  getResort: PropTypes.func.isRequired,
  updateRoomItem: PropTypes.func.isRequired,
  addPhotos: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getResort,
  updateRoomItem,
  addPhotos,
})(RoomItems);
