import {
  GET_AMENITIES,
  GET_AMENITY,
  ADD_AMENITY,
  UPDATE_AMENITY,
  DELETE_AMENITY,
  AMENITY_ERROR,
} from "./types";
import api from "../../utils/api";
import { setError, removeError } from "./error";
import { setAlert, removeAllAlerts } from "./alert";

// Get all amenities
export const getAllAmenities = () => async (dispatch) => {
  try {
    const res = await api.get("/amenity");
    const response = res.data;

    dispatch({
      type: GET_AMENITIES,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: AMENITY_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Get all amenities by resort Id
export const getAllAmenitiesByResort = (resortId) => async (dispatch) => {
  try {
    const res = await api.get(`/resort/${resortId}/amenities`);
    const response = res.data;

    dispatch({
      type: GET_AMENITIES,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: AMENITY_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Get amenity
export const getAmenity = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/amenity/${id}`);
    const response = res.data;

    dispatch({
      type: GET_AMENITY,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: AMENITY_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Add amenity
export const addAmenity = (formData) => async (dispatch) => {
  dispatch(removeError());
  dispatch(removeAllAlerts());

  try {
    const res = await api.post("/amenity", formData);
    const response = res.data;

    dispatch({
      type: ADD_AMENITY,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: AMENITY_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Update amenity
export const updateAmenity = (id, formData) => async (dispatch) => {
  dispatch(removeError());
  dispatch(removeAllAlerts());

  try {
    const res = await api.put(`/amenity/${id}`, formData);
    const response = res.data;

    dispatch({
      type: UPDATE_AMENITY,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: AMENITY_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Delete amenity
export const deleteAmenity = (id) => async (dispatch) => {
  dispatch(removeError());
  dispatch(removeAllAlerts());

  try {
    const res = await api.delete(`/amenity/${id}`);
    const response = res.data;

    dispatch({
      type: DELETE_AMENITY,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));
  } catch (err) {
    const response = err.response.data;

    if (response.data !== null) {
      dispatch(setError(response.data));
    } else {
      dispatch(setAlert(response.message, "error", 5000));
    }

    dispatch({
      type: AMENITY_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};
