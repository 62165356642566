import HeroSearchForm2Mobile from "./HeroSearchForm2Mobile";
import { useLocation } from "react-router-dom";

const PAGES_REAL_ESTATE = [
  "/home-2",
  "/listing-real-estate",
  "/listing-real-estate-map",
];

const HeroSearchForm2MobileFactory = () => {
  const pathname = useLocation().pathname;
  if (PAGES_REAL_ESTATE.includes(pathname)) {
    // return <HeroSearchForm2RealEstateMobile />;
    return "";
  }
  return <HeroSearchForm2Mobile />;
};

export default HeroSearchForm2MobileFactory;
