import { GET_ALL_SCHEDULES, ADD_SCHEDULE, SCHEDULE_ERROR } from "./types";
import api from "../../utils/api";

export const getAllSchedulesByResort = (resortId) => async (dispatch) => {
  try {
    const res = await api.get(`/schedule/resort/${resortId}`);
    const response = res.data;

    dispatch({
      type: GET_ALL_SCHEDULES,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    dispatch({
      type: SCHEDULE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

export const getAllSchedulesByPackage = (packageId) => async (dispatch) => {
  try {
    const res = await api.get(`/schedule/package/${packageId}`);
    const response = res.data;

    dispatch({
      type: GET_ALL_SCHEDULES,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    dispatch({
      type: SCHEDULE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

export const addSchedule = (formData) => async (dispatch) => {
  const { resort_id } = formData;

  try {
    const res = await api.post("schedule", formData);
    const response = res.data;

    dispatch({
      type: ADD_SCHEDULE,
      payload: response.data,
    });

    dispatch(getAllSchedulesByResort(resort_id));
  } catch (err) {
    const response = err.response.data;

    dispatch({
      type: SCHEDULE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};
