import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getResort } from "../../redux/actions/resort";

// Components
import ResortPhotos from "../../components/ResortPhotos/ResortPhotos";
import ResortAmenities from "../../containers/Amenity/ResortAmenities/ResortAmemities";
import ResortRooms from "../../components/Room/ResortRooms/ResortRooms";
import Schedules from "../../components/Schedules/Schedules";

const ViewResort = ({ getResort, loading, resort }) => {
  const { resortId } = useParams();

  useEffect(() => {
    getResort(resortId);
  }, []);

  return (
    <div>
      <div className="inline-flex justify-center align-middle">
        <Link to={`/dashboard/resorts`}>
          <div className="flex justify-center bg-gray-100 w-10 h-10 rounded-full hover:bg-gray-200">
            <ArrowLeftIcon className="w-5 text-gray-500" />
          </div>
        </Link>
        <h1 className="text-2xl ml-5 font-bold text-slate-600">
          <p>View Resort</p>
          <p className="text-sm uppercase text-gray-400">
            {loading ? <Skeleton /> : resort?.name}
          </p>
        </h1>
      </div>

      <ResortRooms resort={resort} loading={loading} />
      <ResortAmenities resort={resort} loading={loading} />
      <ResortPhotos resort={resort} loading={loading} />
      <Schedules resort={resort} />

      <div className="card mt-5">
        <div className="card-header">
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {loading ? <Skeleton /> : resort?.address}
          </span>
        </div>
        <iframe
          title="x"
          width="100%"
          height="100%"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB2BW8rx615MZ2VLVRXrD1suJ7sU0Ibp84&q=${resort?.latlong}&zoom=15`}
        ></iframe>
      </div>
    </div>
  );
};

ViewResort.propTypes = {
  getResort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  resort: state.resortReducer.resort,
  loading: state.resortReducer.loading,
});

export default connect(mapStateToProps, { getResort })(ViewResort);
