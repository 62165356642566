import { useRef, useEffect } from "react";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateAmenity } from "../../../redux/actions/amenity";
import { getResort } from "../../../redux/actions/resort";

// Formik & Yup
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Components
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import Alert from "../../../components/Alert/Alert";

const validationSchema = Yup.object().shape({
  resort_id: Yup.number().required("Resort ID is required"),
  name: Yup.string().required("Name is required"),
});

const UpdateAmemityForm = ({
  cancelButtonRef,
  setShowModal,
  errors,
  resort,
  updateAmenity,
  getResort,
  amenity,
}) => {
  const formikRef = useRef(null);

  const initialValues = {
    resort_id: resort.resort_id,
    name: amenity.name,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    await updateAmenity(amenity.amenity_id, values);
    await getResort(resort.resort_id);
    setSubmitting(false);
  };

  useEffect(() => {
    formikRef.current.setErrors(errors);
  }, [errors, formikRef]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ isSubmitting, setFieldValue, setFieldTouched, errors, touched }) => (
        <Form className="grid grid-cols-1 gap-6 px-5 py-5">
          <Alert />
          <label className="block">
            <span
              className={`text-neutral-800 dark:text-neutral-200 ${
                errors.name && touched.name && "text-red-500 dark:text-red-500"
              }`}
            >
              Name
            </span>
            <Field
              type="text"
              name="name"
              className={`mt-1 ${
                errors.name &&
                touched.name &&
                "border-red-500 text-red-500 dark:border-red-500 dark:text-red-500"
              }`}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
                setFieldTouched("name", true, false);
              }}
            />
            {errors.name && touched.name && (
              <div className="text-red-500 text-sm font-bold">
                {errors.name}
              </div>
            )}
          </label>
          <div className="flex justify-end gap-2">
            <ButtonPrimary type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Updating" : "Update"}
            </ButtonPrimary>
            <ButtonPrimary
              type="button"
              className="bg-red-500 hover:bg-red-400"
              onClick={() => setShowModal(false)}
              ref={cancelButtonRef}
            >
              Cancel
            </ButtonPrimary>
          </div>
        </Form>
      )}
    </Formik>
  );
};

UpdateAmemityForm.propTypes = {
  errors: PropTypes.array.isRequired,
  updateAmenity: PropTypes.func.isRequired,
  getResort: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errorReducer,
});

export default connect(mapStateToProps, { updateAmenity, getResort })(
  UpdateAmemityForm
);
