import NcImage from "../../shared/NcImage/NcImage";
import rightImgDemo from "../../images/BecomeAnAuthorImg.png";
import Logo from "../../shared/Logo/Logo";

const SectionBecomeAnAuthor = ({ className = "", rightImg = rightImgDemo }) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-20" />
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Why did you choose us?
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Accompanying us, you have a trip full of experiences. With IND Travel,
          booking accommodation, resort villas, hotels, private houses,
          apartments... becomes fast, convenient and easy.
        </span>
        {/* <ButtonPrimary className="mt-6 sm:mt-11">
          Become an author
        </ButtonPrimary> */}
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
