import {
  SET_SEARCH_LOCATION,
  SET_SEARCH_GUESTS,
  SET_SEARCH_CHECK_IN,
  SET_SEARCH_CHECK_OUT,
  RESET_SEARCH,
} from "./types";

export const setLocationInputTo = (location) => ({
  type: SET_SEARCH_LOCATION,
  payload: location,
});

export const setGuestInput = (guests) => ({
  type: SET_SEARCH_GUESTS,
  payload: guests,
});

export const setStartDate = (date) => ({
  type: SET_SEARCH_CHECK_IN,
  payload: date,
});

export const setEndDate = (date) => ({
  type: SET_SEARCH_CHECK_OUT,
  payload: date,
});

export const resetSearch = () => ({ type: RESET_SEARCH });
