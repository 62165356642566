import {
  GET_DESTINATION,
  GET_DESTINATIONS,
  ADD_DESTINATION,
  UPDATE_DESTINATION,
  DELETE_DESTINATION,
  DESTINATION_ERROR,
} from "../actions/types";

const initialState = {
  destinations: [],
  destination: null,
  loading: true,
  error: null,
};

const destinationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DESTINATIONS:
      return {
        ...state,
        destinations: payload,
        loading: false,
      };
    case DELETE_DESTINATION:
    case UPDATE_DESTINATION:
    case ADD_DESTINATION:
    case GET_DESTINATION:
      return {
        ...state,
        destination: payload,
        loading: false,
      };
    case DESTINATION_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default destinationReducer;
