import {
  GET_ALL_PACKAGES,
  GET_PACKAGE,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
  PACKAGE_ERROR,
  PACKAGE_LOADING,
} from "./types";
import api from "../../utils/api";
import { setAlert, removeAllAlerts } from "./alert";
import { setError, removeError } from "./error";

// Get all packages
export const getAllPackages = (data) => async (dispatch) => {
  dispatch({ type: PACKAGE_LOADING });
  try {
    const res = await api.get("package", {
      params: data,
    });
    const response = res.data;

    dispatch({
      type: GET_ALL_PACKAGES,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data === null) {
      dispatch(setAlert(response.message, "error", 5000));
    } else {
      dispatch(setError(response.data));
    }

    dispatch({
      type: PACKAGE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Get package
export const getPackage = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/package/${id}`);
    const response = res.data;

    dispatch({
      type: GET_PACKAGE,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data === null) {
      dispatch(setAlert(response.message, "error", 5000));
    } else {
      dispatch(setError(response.data));
    }

    dispatch({
      type: PACKAGE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Get package
export const getPackageBySlug = (slug) => async (dispatch) => {
  try {
    const res = await api.get(`/package/slug/${slug}`);
    const response = res.data;

    dispatch({
      type: GET_PACKAGE,
      payload: response.data,
    });
  } catch (err) {
    const response = err.response.data;

    if (response.data === null) {
      dispatch(setAlert(response.message, "error", 5000));
    } else {
      dispatch(setError(response.data));
    }

    dispatch({
      type: PACKAGE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Add package
export const addPackage = (formData) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());
  try {
    const res = await api.post("/package", formData);
    const response = res.data;

    dispatch({
      type: ADD_PACKAGE,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));

    dispatch(getAllPackages());
  } catch (err) {
    const response = err.response.data;

    if (response.data === null) {
      dispatch(setAlert(response.message, "error", 5000));
    } else {
      dispatch(setError(response.data));
    }

    dispatch({
      type: PACKAGE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Update package
export const updatePackage = (id, formData) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());
  try {
    const res = await api.put(`/package/${id}`, formData);
    const response = res.data;

    dispatch({
      type: UPDATE_PACKAGE,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));

    dispatch(getAllPackages());
  } catch (err) {
    const response = err.response.data;

    if (response.data === null) {
      dispatch(setAlert(response.message, "error", 5000));
    } else {
      dispatch(setError(response.data));
    }

    dispatch({
      type: PACKAGE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};

// Delete package
export const deletePackage = (id) => async (dispatch) => {
  dispatch(removeAllAlerts());
  dispatch(removeError());
  try {
    const res = await api.delete(`/package/${id}`);
    const response = res.data;

    dispatch({
      type: DELETE_PACKAGE,
      payload: response.data,
    });

    dispatch(setAlert(response.message, "success", 5000));

    dispatch(getAllPackages());
  } catch (err) {
    const response = err.response.data;

    if (response.data === null) {
      dispatch(setAlert(response.message, "error", 5000));
    } else {
      dispatch(setError(response.data));
    }

    dispatch({
      type: PACKAGE_ERROR,
      payload: {
        status: response.status,
        msg: response.message,
      },
    });
  }
};
