import { useState } from "react";
import DataTable from "react-data-table-component";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { AmenityColumns } from "../../../constants/columns";

// Redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getResort } from "../../../redux/actions/resort";
import { updateAmenity } from "../../../redux/actions/amenity";

// Components
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import AddAmenity from "../AddAmenity/AddAmenity";
import UpdateAmenity from "../UpdateAmenity/UpdateAmemity";
import DeleteAmenity from "../DeleteAmenity/DeleteAmenity";

const ResortAmenities = ({ resort, loading, getResort, updateAmenity }) => {
  const [isAddAmenity, setIsAddAmenity] = useState(false);
  const [isUpdateAmenity, setIsUpdateAmenity] = useState(false);
  const [isDeleteAmenity, setIsDeleteAmenity] = useState(false);
  const [amenityData, setAmenityData] = useState({});

  const toggleIsChargedHandler = (checked, row) => {
    updateAmenity(row.amenity_id, {
      resort_id: row.resort_id,
      name: row.name,
      is_charged: checked,
    });
    getResort(row.resort_id);
  };

  return (
    <div className="card mt-5">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <p className="card-title">Amenities</p>
          <button
            type="button"
            className="text-blue-500"
            onClick={() => setIsAddAmenity(true)}
          >
            <PlusCircleIcon className="w-6" />
          </button>
        </div>
      </div>
      <DataTable
        columns={AmenityColumns({
          setAmenityData,
          setIsUpdateAmenity,
          setIsDeleteAmenity,
          toggleIsChargedHandler,
        })}
        data={resort?.amenities ?? []}
        progressPending={loading}
        progressComponent={<LoadingSpinner />}
        pagination
        paginationServer
      />
      <AddAmenity
        showModal={isAddAmenity}
        setShowModal={setIsAddAmenity}
        resort={resort}
      />
      <UpdateAmenity
        showModal={isUpdateAmenity}
        setShowModal={setIsUpdateAmenity}
        resort={resort}
        amenity={amenityData}
      />
      <DeleteAmenity
        showModal={isDeleteAmenity}
        setShowModal={setIsDeleteAmenity}
        resort={resort}
        amenity={amenityData}
      />
    </div>
  );
};

ResortAmenities.propTypes = {
  getResort: PropTypes.func.isRequired,
  updateAmenity: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getResort, updateAmenity })(
  ResortAmenities
);
