import { useState } from "react";
import DataTable from "react-data-table-component";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { RoomColumns } from "../../../constants/columns";

// Components
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import AddRoom from "../AddRoom/AddRoom";
import UpdateRoom from "../UpdateRoom/UpdateRoom";
import DeleteRoom from "../DeleteRoom/DeleteRoom";
import RoomItems from "../RoomItems/RoomItems";

const ResortRooms = ({ resort, loading }) => {
  const [isAddRoom, setIsAddRoom] = useState(false);
  const [isUpdateRoom, setIsUpdateRoom] = useState(false);
  const [isDeleteRoom, setIsDeleteRoom] = useState(false);
  const [roomData, setRoomData] = useState({});

  return (
    <div className="card mt-5">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <p className="card-title">Rooms</p>
          <button
            type="button"
            className="text-blue-500"
            onClick={() => setIsAddRoom(true)}
          >
            <PlusCircleIcon className="w-6" />
          </button>
        </div>
      </div>
      <DataTable
        columns={RoomColumns({
          setIsUpdateRoom,
          setRoomData,
          setIsDeleteRoom,
        })}
        data={resort?.rooms ?? []}
        progressPending={loading}
        progressComponent={<LoadingSpinner />}
        pagination
        paginationServer
        expandableRows={true}
        expandableRowsComponent={RoomItems}
      />
      <AddRoom
        showModal={isAddRoom}
        setShowModal={setIsAddRoom}
        resort={resort}
      />
      <UpdateRoom
        showModal={isUpdateRoom}
        setShowModal={setIsUpdateRoom}
        resort={resort}
        room={roomData}
      />
      <DeleteRoom
        showModal={isDeleteRoom}
        setShowModal={setIsDeleteRoom}
        resort={resort}
        room={roomData}
      />
    </div>
  );
};

export default ResortRooms;
