import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import api from "../../utils/api";
import formatDate from "../../utils/formatDate";

const ButtonSubmit = ({
  className = "",
  onClick = () => {},
  href = "/listing-stay",
  startDate,
  endDate,
  locationInputTo,
  guestInput,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    const formData = {
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      destination: locationInputTo,
      adults: guestInput.guestAdults,
      children: guestInput.guestChildren,
      infants: guestInput.guestInfants,
    };

    try {
      const res = await api.post("/available-package/search", formData);
      const response = res.data;

      const searchResult = {
        destination: locationInputTo,
        results: response.data,
        total_result: response.data.length,
        searchParams: {
          start_date: formData.start_date,
          end_date: formData.end_date,
          total_guests: formData.adults + formData.children + formData.infants,
        },
      };

      localStorage.setItem("search_result", JSON.stringify(searchResult));
      window.location.href = "/search-result";
    } catch (err) {
      const response = err.response.data;
      console.log(response.data);
    }
  };

  return (
    <button
      disabled={startDate && endDate && locationInputTo !== "" ? false : true}
      type="submit"
      onClick={handleSearch}
      className={`flex-shrink-0 px-4 py-2.5 cursor-pointer rounded-xl bg-primary-6000 flex items-center justify-center text-neutral-50 focus:outline-none ${className} relative z-20`}
    >
      {loading ? (
        <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          <span className="ml-2">Search</span>
        </>
      )}
    </button>
  );
};

ButtonSubmit.propTypes = {
  locationInputTo: PropTypes.string.isRequired,
  guestInput: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  locationInputTo: state.searchReducer.locationInputTo,
  guestInput: state.searchReducer.guestInput,
  startDate: state.searchReducer.startDate,
  endDate: state.searchReducer.endDate,
});

export default connect(mapStateToProps)(ButtonSubmit);
